<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import axios from 'axios';

import Multiselect from "vue-multiselect";
import InputTag from 'vue-input-tag';
import vue2Dropzone from "vue2-dropzone";
import {saveAs} from 'file-saver';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  minValue,
  maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Member registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, InputTag, vueDropzone: vue2Dropzone },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Members",
          href: "#/members"
        },
        {
          text: "Member",
          active: true
        }
      ],

      load:                 0,
      chartConfig:          null,
      pools:                [],
      coaches:              [],
      schedules:            [],
      groups:               [],
      teams:                [],
      routines:             [],
      trainings:            [],
      materials:            [],
      series:               [],
      performances:         [],
      selectedPool:         "",
      selectedCoach:        "",
      selectedSchedule:     "",
      selectedGroup:        "",
      selectedTeam:         "",
      selectedRoutines:     "",
      selectedMaterials:    "",
      server:               "",

      form: {
        id_pool:            "",
        id_group:           "",
        id_schedule:        "",
        id_coach:           "",
        id_team:            "",
        id_training:        "",
        id_status:          "",
        de_gender:          "",
        nm_member:          "",
        nm_lastName:        "",
        num_age:            0,
        num_phoneNumber:    "",
        de_email:           "",
        dt_birthday:        "",
        de_materials:       [],
        de_specialistOn:    [],
        de_selectedRoutines:[],
        url_photograph:     "",
        sn_parentalConsent: "",
        num_diameter: "",
        num_height: "",
        num_longitude: "",
        num_weight: "",
        num_size: "",
      },
      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/members.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },
      submitted: false,
      validToken: false,
      options:{
        format: 'YYYY-MM-DD',
      }
    };
  },
  validations: {
    form: {
      nm_member:          { required, minLength: minLength(2), maxLength: maxLength(100) },
      nm_lastName:        { required, minLength: minLength(2), maxLength: maxLength(100) },
      num_age:            { minValue:minValue(8), maxValue:maxValue(100) },
      num_phoneNumber:    { required },
      de_email:           { required, email },
      de_gender:          { required },
      dt_birthday:        { required },
      id_pool:            { required },
      id_coach:           { required },
    }
  },
  watch:{
    load: function(){
      let app = this;
      if(this.load == 4 ){
        this.pools.forEach(function(pool){
          if(app.form.id_pool == pool.id_pool){
            app.selectedPool = pool;
          }
        });

        this.coaches.forEach(function(coach){
          if(app.form.id_coach == coach.id_coach){
            app.selectedCoach = coach;
          }
        });

        this.teams.forEach(function(team){
          if(app.form.id_team == team.id_team){
            app.selectedTeam = team;
          }
        });

        this.getScheduleByPoolAndCoachID();
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath+'dist';
    this.getSchedules();
    this.getPools();
    this.getCoaches();
    //this.getGroups();
    this.getTeams();
    this.getRoutines();
    this.getTrainings();
    this.getMaterials();

    if( this.$route.params.id ){
      this.getMemberByID();
    }
  },
  methods: {
    async getMemberByID(){
      //let app = this;

      Swal.fire({
        title: "Loading member data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/members.php',
        {
          params: {
            request: 'getMemberByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.form.id_pool           = response.data.id_pool;
          this.form.id_coach          = response.data.id_coach;
          this.form.id_group          = response.data.id_group;
          this.form.id_schedule       = response.data.id_schedule;
          this.form.id_team           = response.data.id_team;
          this.form.id_training       = response.data.id_training;
          this.form.id_status         = response.data.id_status;
          this.form.de_gender         = response.data.de_gender;
          this.form.nm_member         = response.data.nm_member;
          this.form.nm_lastName       = response.data.nm_lastName;
          this.form.num_age           = response.data.num_age;
          this.form.dt_birthday       = response.data.dt_birthday+" 00:00:00Z";
          this.form.num_phoneNumber   = response.data.num_phoneNumber;
          this.form.de_email          = response.data.de_email;
          this.form_de_materials      = response.data.de_materials;
          this.form.de_specialistOn   = response.data.de_specialistOn;
          this.form.url_photograph    = response.data.url_photograph;

          this.form.num_diameter       = response.data.num_diameter;
          this.form.num_height         = response.data.num_height;
          this.form.num_longitude      = response.data.num_longitude;
          this.form.num_weight         = response.data.num_weight;
          this.form.num_size           = response.data.num_size;

          this.form.de_selectedRoutines = (response.data.de_selectedRoutines == "" || response.data.de_selectedRoutines == null) ? null : JSON.parse(response.data.de_selectedRoutines);
          this.selectedRoutines         = (response.data.de_selectedRoutines == "" || response.data.de_selectedRoutines == null) ? null : JSON.parse(response.data.de_selectedRoutines);
          this.selectedMaterials        = ( Array.isArray(response.data.de_materials) ) ? response.data.de_materials : "" ;

          if(response.data.sn_parentalConsent == 1){
            this.form.sn_parentalConsent = true;
          }

          this.getPerformanceByMemberID();
          this.load ++;

          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to member data! Please load this module again.", "error");
        }
      });
    },

    async getPerformanceByMemberID(){
      //let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/performances.php',
        {
          params: {
            request: 'getPerformanceByMemberID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
            Swal.close();
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              if( Array.isArray(response.data) && response.data.length > 0 ){
                this.performances = response.data;

                var diameterSeries  = this.getPerformanceSeries('num_diameter');
                var heightSeries    = this.getPerformanceSeries('num_height');
                var longitudeSeries = this.getPerformanceSeries('num_longitude');
                var weightSeries    = this.getPerformanceSeries('num_weight');
                var sizeSeries      = this.getPerformanceSeries('num_size');
                var liftSeries      = this.getPerformanceSeries('num_lift');
                var timeSeries      = this.getPerformanceSeries('num_time');
                var distanceSeries  = this.getPerformanceSeries('num_distance');
                var categories      = this.getPerformanceSeries('dt_performance');
                var xaxis           = { categories: categories };

                this.series = [
                  { name: "Diameter", data: diameterSeries},
                  { name: "Height", data: heightSeries},
                  { name: "Longitude", data: longitudeSeries},
                  { name: "Weight", data: weightSeries},
                  { name: "Size", data: sizeSeries},
                  { name: "Lift", data: liftSeries},
                  { name: "Time", data: timeSeries},
                  { name: "Distance", data: distanceSeries}
                ];

                this.chartConfig = {
                  series: this.series,
                  chartOptions: {
                    chart: {
                        zoom:     { enabled: true },
                        toolbar:  {
                          show: true,
                          tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                            customIcons: []
                          },
                        },
                        export: {
                          csv: {
                            filename: "my_performance"+(new Date()).toISOString()+".csv",
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value'
                          },
                          svg: {
                            filename: "my_performance"+(new Date()).toISOString()+".svg",
                          },
                          png: {
                            filename: "my_performance"+(new Date()).toISOString()+".png",
                          }
                        },
                    },
                    colors: ['#556ee6', '#f46a6a', '#34c38f', '#fcb92c', '#fc2ceb', '#34495e', '#95a5a6', '#d35400'],
                    dataLabels: { enabled: false },
                    stroke: {
                        width: [3,3,3,3,3],
                        curve: 'straight',
                        dashArray: [0,0,0,0,0]
                    },
                    title: {
                        text: this.form.nm_member + " " + this.form.nm_lastName + " performance.",
                        align: 'left'
                    },
                    markers: {
                        size: 5,
                        hover: {
                            sizeOffset: 6
                        }
                    },
                    xaxis: xaxis,
                    tooltip: {
                        y: [
                          { title: { formatter: function (val) { return val + " (cm)" } } },
                          { title: { formatter: function (val) { return val + " (mt)"  } } },
                          { title: { formatter: function (val) { return val + " (cm)"  } } },
                          { title: { formatter: function (val) { return val + " (kg)"  } } },
                          { title: { formatter: function (val) { return val + ""  } } },
                          { title: { formatter: function (val) { return val + " (kg)"  } } },
                          { title: { formatter: function (val) { return val + " (minutes)"  } } },
                          { title: { formatter: function (val) { return val + " (mt)"  } } },
                        ]
                    },
                    grid: { borderColor: '#f1f1f1', }
                  }
                }
              }else{
                this.performances = [];
              }
            }
          }else{
            Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
          }
      });
    },

    getPerformanceSeries(serie){
      var array = [];
      this.performances.forEach(function(progress){
        for(var key in progress){
          if( key == serie ){
            if( !isNaN(progress[key]) ){
              array.push(progress[key]);
            }else{
              array.push(0.00);
            }
          }
        }
      });
      return array;
    },

    async getPools(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/pools.php',
        {
          params: {
            request: 'getPools',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            Swal.close();
            this.validToken = false;
          }else{
            this.pools = response.data;
            this.load++;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load pools data! Please load this module again.", "error");
        }
      });
    },

    async getCoaches(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/coaches.php',
        {
          params: {
            request: 'getCoaches',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.coaches = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load coaches data! Please load this module again.", "error");
        }
      });
    },

    async getSchedules(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/schedules.php',
        {
          params: {
            request: 'getSchedules',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.schedules = response.data;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    async getGroups(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/groups.php',
        {
          params: {
            request: 'getGroups',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.groups = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    async getGroupsByScheduleID(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/groups.php',
        {
          params: {
            request: 'getGroupsByScheduleID',
            token: this.user.token,
            id_schedule: this.selectedSchedule.id_schedule
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.groups = response.data;
          //this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    async getTeams(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/teams.php',
        {
          params: {
            request: 'getTeams',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.teams = response.data;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    async getRoutines(){
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/routines.php',
        {
          params: {
            request: 'getRoutinesForMultipleSelect',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            if(Array.isArray(response.data)){
              app.routines = response.data;
            }
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
        }
      });
    },

    async getTrainings(){
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/trainings.php',
        {
          params: {
            request: 'getTrainingsForMultipleSelect',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            if(Array.isArray(response.data)){
              app.trainings = response.data;
            }
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
        }
      });
    },

    async getMaterials(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/materials.php',
        {
          params: {
            request: 'getMaterialsForMultipleSelect',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.materials = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load materials data! Please load this module again.", "error");
        }
      });
    },

    async getScheduleByPoolAndCoachID(){
      let app = this;

      if(!this.$route.params.id){
        this.$bvToast.toast(
          'Loading '+this.selectedCoach.nm_coach+' '+this.selectedCoach.nm_lastName+' coach schedules.',
          {
            title: 'Loading...',
            variant: 'info',
            solid: true
          }
        );
      }

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/groups.php',
        {
          params: {
            request: 'getGroupsByPoolAndCoachID',
            id_pool: this.selectedPool.id_pool,
            id_coach: this.selectedCoach.id_coach,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(response.data.length > 0){
            this.groups = response.data;
            //this.load++;

            if(this.$route.params.id){
              this.groups.forEach(function(group){
                if(app.form.id_group == group.id_group){
                  app.selectedGroup = group;
                }
              });
            }else{
              this.$bvToast.toast(
                'Schedules successfully loaded.',
                {
                  title: 'Done',
                  variant: 'success',
                  solid: true
                }
              );
            }
          }else{
            this.$bvToast.toast(
              'No groups found associated to the selected pool/coach, please try again or try with another pool/coach.',
              {
                title: 'Done',
                variant: 'warning',
                solid: true
              }
            );
          }
          
        }else{
          this.$bvToast.toast(
            "Ooops!", "Seems like some error ocurr, please try again.",
            {
              title: 'Loading error',
              variant: 'error',
              solid: true
            }
          );
        }
      });
    },

    getAge(dateString) {
      console.log(dateString);
      
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      this.form.num_age = age;
    },

    // eslint-disable-next-line no-unused-vars
    saveMember(e) {
      let app = this;

      this.submitted          = true;
      // stop here if form is invalid
      this.$v.$touch();

      this.form.id_pool             = this.selectedPool.id_pool;
      this.form.id_coach            = this.selectedCoach.id_coach;
      this.form.id_group            = this.selectedGroup.id_group;
      this.form.id_team             = this.selectedTeam.id_team;
      this.form.id_schedule         = this.selectedGroup.id_schedule;
      this.form.de_selectedRoutines = this.selectedRoutines;
      this.form.de_materials        = this.selectedMaterials;

      if( this.form.num_age >= 18){
        this.form.sn_parentalConsent = true;
      }

      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_member = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving the member data.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
          this.$refs.myVueDropzone.processQueue();
        }else{
          axios.post(window.serverPath+'dist/mvw/controllers/members.php', {
            request: 'saveMember',
            data: this.form,
            token: this.user.token,
          })
          .then(function (response) {
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "The member is correct "+action+"!", "success")
            .then(function(response){
              if(response.isConfirmed){
                app.$router.push({ name: 'members' });
              }
            });
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'saveMember');
      formData.append('token',    this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },

    uploadCompleted(response){
      let app = this;
      
      console.log(response);
      if(response.status != "error"){
        Swal.close();
        Swal.fire("Good job!", "The team is correct saved!", "success")
        .then(function(response){
          if(response.isConfirmed){
            app.$router.push({ name: 'members' });
          }
        });
      }else{
        Swal.close();
        Swal.fire("Ooops!", "There was an error when trying to save this team data. Please try again.", "error");
      }
    },

    downloadTicket(){
      //let app = this;
      axios
      .get(window.serverPath+'dist'+this.form.url_photograph, {responseType: 'blob'})
      .then(response => {
          saveAs(response.data);
      })
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_photograph);
    },

    consoleThis(){
      console.log(this.form.de_materials);
    },

    selectThisOption(id){
      this.form.de_materials.forEach(function(material){
        console.log(material);
        if(material == id){
          return "selected";
        }
      });
    },
    goToPerformance(idMember, form){
      this.$router.push({ name:"performanceEdit", params:{id: idMember, member: form} });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveMember">
              <div class="row">
                <div class="col-sm-3">
                  <h4 class="card-title">{{ $t('menuitems.members.list.personalData') }}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_member">{{ $t('menuitems.members.list.name') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_member.$error }"
                          name="nm_member"
                          id="nm_member"
                          v-model="form.nm_member"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_member.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_member.required">Member name is required.</span>
                          <span v-if="!$v.form.nm_member.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_member.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_lastName">{{ $t('menuitems.members.list.lastName') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_lastName.$error }"
                          name="nm_lastName"
                          id="nm_lastName"
                          v-model="form.nm_lastName"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_lastName.required">Member last name is required.</span>
                          <span v-if="!$v.form.nm_lastName.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_lastName.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label for="dt_birthday">{{ $t('menuitems.members.list.birthday') }}</label>
                        <br />
                        <date-picker
                          v-model="form.dt_birthday"
                          :first-day-of-week="1"
                          lang="en"
                          :class="{ 'is-invalid': submitted && $v.form.dt_birthday.$error }"
                          name="dt_birthday"
                          id="dt_birthday"
                          valueType="format"
                          format="YYYY-MM-DD"
                          :value="form.dt_birthday"
                          @change="getAge"
                        >
                        </date-picker>
                        <div v-if="submitted && $v.form.dt_birthday.$error" class="invalid-feedback">
                          <span v-if="!$v.form.dt_birthday.required">Member hires date is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_gender">{{ $t('menuitems.members.list.gender') }}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_gender.$error }"
                          name="de_gender"
                          id="de_gender"
                          v-model="form.de_gender"
                        >
                          <option value="">{{ $t('menuitems.members.list.selectGender') }}</option>
                          <option value="FEMALE">{{ $t('menuitems.members.list.female') }}</option>
                          <option value="MALE">{{ $t('menuitems.members.list.male') }}</option>
                          <option value="NONE BINARY">{{ $t('menuitems.members.list.none') }}</option>
                        </select>
                        <div v-if="submitted && $v.form.de_gender.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_gender.required">Gender is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="num_phoneNumber">{{ $t('menuitems.members.list.phoneNumber') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          name="num_phoneNumber"
                          id="num_phoneNumber"
                          v-model="form.num_phoneNumber"
                          :class="{ 'is-invalid': submitted && $v.form.num_phoneNumber.$error }"
                        />
                        <div v-if="submitted && $v.form.num_phoneNumber.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_phoneNumber.required">Phone number is required.</span>
                        </div>
                        <!--<span class="text-muted">e.g "(xx) xxxx-xxxx"</span>-->
                      </div>

                      <div class="form-group">
                        <label for="de_email">{{ $t('menuitems.members.list.email') }}</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_email.$error }"
                          name="de_email"
                          id="de_email"
                          v-model="form.de_email"
                        >
                        <div v-if="submitted && $v.form.de_email.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_email.required">Member email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="num_age">{{ $t('menuitems.members.list.age') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_age.$error }"
                          name="num_age"
                          id="num_age"
                          v-model="form.num_age"
                          value=""
                        />
                        <div v-if="submitted && $v.form.num_age.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_age.required">Member age is required.</span>
                          <span v-if="!$v.form.num_age.minValue">This minimum age is invalid. It should be between 18 and 100 years old.</span>
                          <span v-if="!$v.form.num_age.maxValue">This maximum age is invalid. It should be between 18 and 100 years old.</span>
                        </div>
                      </div>

                      <div class="form-group" v-if="form.num_age < 18">
                        <div class="form-group">
                          <b-form-checkbox v-model="form.sn_parentalConsent" switch class="mb-1">
                            <label>{{ $t('menuitems.members.list.parental')}}</label>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3">
                  <h4 class="card-title">{{ $t('menuitems.members.list.otherData') }}</h4>
                  

                  <div class="form-group">
                    <label for="id_pool">{{ $t('menuitems.members.list.pool') }}</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_pool.$error }"
                      name="id_pool"
                      id="id_pool"
                      v-model="selectedPool"
                    >
                      <option value="">{{ $t('menuitems.members.list.selectPool') }}</option>
                      <option v-for="(pool, index) in pools" :key="index" :value="pool">{{pool.nm_pool}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_pool.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_pool.required">Pool is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_coach">{{ $t('menuitems.members.list.coach') }}</label>
                    <select
                      class="form-control"
                      name="id_coach"
                      id="id_coach"
                      v-model="selectedCoach"
                      :class="{ 'is-invalid': submitted && $v.form.id_coach.$error }"
                      @change="getScheduleByPoolAndCoachID"
                    >
                      <option value="">{{ $t('menuitems.members.list.selectCoach') }}</option>
                      <option v-for="(coach, index) in coaches" :key="index" :value="coach">{{coach.nm_coach + " " + coach.nm_lastName}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_coach.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_coach.required">Coach is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_schedule">{{ $t('menuitems.members.list.schedule') }}</label>
                    <select
                      class="form-control"
                      name="id_schedule"
                      id="id_schedule"
                      v-model="selectedSchedule"
                      @change="getGroupsByScheduleID"
                    >
                      <option value="">{{ $t('menuitems.members.list.selectSchedule') }}</option>
                      <option v-for="(schedule, index) in schedules" :key="index" :value="schedule">{{schedule.days + " " +schedule.schedule}}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="id_group">{{ $t('menuitems.members.list.group') }}</label>
                    <select
                      class="form-control"
                      name="id_group"
                      id="id_group"
                      v-model="selectedGroup"
                    >
                      <option value="">{{ $t('menuitems.members.list.selectGroup') }}</option>
                      <option v-for="(group, index) in groups" :key="index" :value="group">{{group.schedule}}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="id_team">{{ $t('menuitems.members.list.team') }}</label>
                    <select
                      class="form-control"
                      name="id_team"
                      id="id_team"
                      v-model="selectedTeam"
                    >
                      <option value="">{{ $t('menuitems.members.list.selectTeam') }}</option>
                      <option v-for="(team, index) in teams" :key="index" :value="team">{{team.nm_team}}</option>
                    </select>
                  </div>

                  <div class="form-group" v-if="form.id_status != ''">
                    <label for="id_status">{{ $t('menuitems.members.list.status') }}</label>
                    <select
                      class="form-control"
                      name="id_status"
                      id="id_status"
                      v-model="form.id_status"
                    >
                      <option value="">{{ $t('menuitems.members.list.selectStatus') }}</option>
                      <option value="0">{{ $t('menuitems.members.list.inactive') }}</option>
                      <option value="1">{{ $t('menuitems.members.list.active') }}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="selectedRoutines">{{ $t('menuitems.trainings.list.routines') }}</label>
                    <multiselect
                      id="selectedRoutines"
                      v-model="selectedRoutines"
                      :options="routines"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :placeholder="$t('menuitems.trainings.list.selectRoutines')"
                      label="label"
                      track-by="label"
                      :preselect-first="false"
                    ></multiselect>
                  </div>

                  <div class="form-group">
                    <label for="id_training">{{ $t('menuitems.trainings.list.training') }}</label>
                    <select
                      class="form-control"
                      name="id_training"
                      id="id_training"
                      v-model="form.id_training"
                    >
                      <option value="0">{{ $t('menuitems.trainings.list.selectTraining') }}</option>
                      <option v-for="(training, index) in trainings" :key="index" :value="training.action">{{ training.label }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-3">
                  <h4 class="card-title">{{ $t('menuitems.members.list.picture') }}</h4>
                  <div class="form-group">
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      {{ $t('menuitems.members.list.downloadPhotograph') }}
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" v-b-modal.modal-member>
                      {{ $t('menuitems.members.list.viewPhotograph') }}
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>

                    <b-modal no-close-on-backdrop
                      id="modal-member"
                      title="Member photograph"
                      title-class="font-18"
                      hide-footer
                      size="xl"
                      centered
                    >
                      <img :src="server+form.url_photograph" width="100%" />
                    </b-modal>

                    <label for="dropzone" :class="{ 'mb-4': form.url_photograph != '' }">&nbsp;</label>
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>{{ $t('menuitems.members.list.uploadPhotograph') }}</h4>
                      </div>
                    </vue-dropzone>
                  </div>

                  <div class="form-group">
                    <label for="de_materials">{{ $t('menuitems.members.list.materials') }}</label>
                    <multiselect
                      id="id_pools"
                      v-model="selectedMaterials"
                      :options="materials"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :placeholder="$t('menuitems.members.list.selectMaterials')"
                      label="label"
                      track-by="label"
                      :preselect-first="false"
                    ></multiselect>
                    <!--
                    <select
                      multiple
                      class="form-control"
                      name="de_materials"
                      id="de_materials"
                      v-model="form.de_materials"
                      size="10"
                      @change="consoleThis"
                    >
                      <option
                        v-for="(material, index) in materials"
                        :key="index"
                        :value="material"
                      >
                        {{material.nm_material}}
                      </option>
                    </select>
                    -->
                  </div>

                  <div class="form-group">
                    <label for="de_specialistOn">{{ $t('menuitems.members.list.specialistOn') }}</label>
                    <input-tag
                      name="de_specialistOn"
                      id="de_specialistOn"
                      v-model="form.de_specialistOn"
                      :limit="10"
                      v-bind:placeholder="$t('menuitems.members.list.features')"
                      class="form-control"
                      height="10"
                    >
                    </input-tag>
                  </div>
                </div>

                <div class="col-sm-3">
                  <h4 class="card-title">
                    {{ $t('menuitems.members.list.physicalData') }}
                    <div class="d-inline-block" align="right">
                    <b-dropdown id="dropdown-right" right size="sm" text="⁝ OPTIONS" variant="none" class="m-0 p-0">
                      <b-dropdown-item v-b-modal.modal-memberPerformance><i class="ri-history-line"></i> History</b-dropdown-item>
                      <b-dropdown-item v-b-modal.modal-memberPerformanceChart><i class="ri-bar-chart-2-fill"></i> View statistics</b-dropdown-item>
                      <b-dropdown-item @click="goToPerformance($route.params.id, form)"><i class="ri-add-line"></i> Add performance</b-dropdown-item>
                    </b-dropdown>
                    </div>
                  </h4>
                  <div class="form-group">
                    <label for="num_diameter">{{ $t('menuitems.members.list.diameter') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_diameter"
                      id="num_diameter"
                      v-model="form.num_diameter"
                      value=""
                      placeholder="Expressed in centimetters."
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_height">{{ $t('menuitems.members.list.height') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_height"
                      id="num_height"
                      v-model="form.num_height"
                      value=""
                      placeholder="Expressed in metters."
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_longitude">{{ $t('menuitems.members.list.longitude') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_longitude"
                      id="num_longitude"
                      v-model="form.num_longitude"
                      value=""
                      placeholder="Expressed in centimetters."
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_weight">{{ $t('menuitems.members.list.weight') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_weight"
                      id="num_weight"
                      v-model="form.num_weight"
                      value=""
                      placeholder="Expressed in kilograms."
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_size">{{ $t('menuitems.members.list.size') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_size"
                      id="num_size"
                      v-model="form.num_size"
                      value=""
                      placeholder="Expressed in integer."
                    >
                  </div>

                  <div class="form-group">
                    <label for="num_lift">{{ $t('menuitems.members.list.size') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_lift"
                      id="num_lift"
                      v-model="form.num_lift"
                      value=""
                      placeholder="Expressed in kilograms."
                    >
                  </div>

                  <div class="form-group">
                    <label for="num_time">{{ $t('menuitems.members.list.size') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_time"
                      id="num_time"
                      v-model="form.num_time"
                      value=""
                      placeholder="Expressed in minutes."
                    >
                  </div>

                  <div class="form-group">
                    <label for="num_distance">{{ $t('menuitems.members.list.size') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_distance"
                      id="num_distance"
                      v-model="form.num_distance"
                      value=""
                      placeholder="Expressed in metters."
                    >
                  </div>

                  <b-modal no-close-on-backdrop
                    id="modal-memberPerformance"
                    title="Performance History"
                    title-class="font-18"
                    hide-footer
                    size="xl"
                    centered 
                  >
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="table-responsive" v-if="performances.length>0">
                          <table class="table mb-0 table-bordered table-striped" :fixed="true" style="min-width: 300px !important;">
                            <thead class="thead-dark">
                              <tr>
                                <th>{{ $t('menuitems.members.list.performanceDate') }}</th>
                                <th>{{ $t('menuitems.members.list.diameter') }}</th>
                                <th>{{ $t('menuitems.members.list.height') }}</th>
                                <th>{{ $t('menuitems.members.list.longitude') }}</th>
                                <th>{{ $t('menuitems.members.list.weight') }}</th>
                                <th>{{ $t('menuitems.members.list.size') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(performance, index) in performances" :key="index">
                                <td scope="row">{{performance.dt_performance}}</td>
                                <td>{{performance.num_diameter}}</td>
                                <td>{{performance.num_height}}</td>
                                <td>{{performance.num_longitude}}</td>
                                <td>{{performance.num_weight}}</td>
                                <td>{{performance.num_size}}</td> scope="row"
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h4 v-else>No performance details recorded.</h4>
                      </div>
                    </div>
                  </b-modal>

                  <b-modal no-close-on-backdrop
                    id="modal-memberPerformanceChart"
                    title="Performance Chart"
                    title-class="font-18"
                    hide-footer
                    size="lg"
                    centered 
                  >
                    <apexchart
                      v-if="chartConfig != null"
                      class="apex-charts"
                      height="380"
                      type="line"
                      dir="ltr"
                      :series="chartConfig.series"
                      :options="chartConfig.chartOptions"
                    ></apexchart>
                    <h4 v-else>No performance details recorded.</h4>
                  </b-modal>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{ $t('menuitems.members.list.save') }}</button>
                    <router-link to="/members" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{ $t('menuitems.members.list.cancel') }}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-input-tag-wrapper {
    height: auto !important;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #5664d2 !important;
    border: 1px solid #5664d2 !important;
    color: #fff !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff !important;
  }

  .dropzone{
    min-height: 100px !important;
    padding:  0px !important;
    margin: 0px !important;
  }
</style>