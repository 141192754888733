<script type="text/javascript" src="cordova.js"></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import vue2Dropzone from "vue2-dropzone";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  //requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Labor registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403, vueDropzone: vue2Dropzone /*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "Labor registration",
      items: [
        {
          text: "Management",
          href: "/"
        },
        {
          text: "Payments",
          href: "/finances/payments"
        },
        {
          text: "Payment",
          active: true
        }
      ],
      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/payments.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },

      form: {
        id_company:           "",
        id_paymentType:       "",
        id_suscriptionType:   "",
        num_totalPayment:     0,
        url_invoice:          "",
      },

      platform:     "",
      submitted:    false,
      validToken:   true,
      validAccess:  true,
      user:         {},
      companies:    [],
    };
  },
  validations: {
    form: {
      id_company:         { required },
      id_paymentType:     { required },
      id_suscriptionType: { required },
      num_totalPayment:   { required, minValue:minValue(1) },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    document.addEventListener("deviceready", this.onDeviceReady, false);

    this.getOrganizations();
    
    if( this.$route.params.id ){
      this.getPayment();
    }
  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform = platform;
      }
    },

    async getOrganizations(){
      Swal.fire({
        title: "Loading companies",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/organizations.php',
        {
          params: {
            request: 'getOrganizations',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.companies = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getPayment(){
      Swal.fire({
        title: "Loading payment data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/payments.php',
        {
          params: {
            request: 'getPaymentByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.form = response.data;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'savePayment');
      formData.append('token',     this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },

    uploadCompleted(response){
      let app = this;

      response;
      Swal.fire("Good job!", "Payment has been successfully saved!", "success")
      .then(function(confirmation){
        if(confirmation.isConfirmed){
         app.$router.go();
        }
      });
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_invoice);
    },

    downloadTicket(){
      if(this.platform != "" ){
        navigator.app.loadUrl(window.serverPath+'dist'+this.form.url_invoice, { openExternal: true });
      }else{
        window.open(window.serverPath+'dist'+this.form.url_invoice, "_blank");
      }
    },

    // eslint-disable-next-line no-unused-vars
    savePayments(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_payment = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving payment.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
          this.$refs.myVueDropzone.processQueue();
        }else{
          axios.post(window.serverPath+'dist/mvw/controllers/payments.php', {
            request: 'savePayment',
            data: this.form,
            token: this.user.token,
          })
          .then(function (response) {
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              let action = "";
              if( response.data === "OK" ){
                action = "saved";
              }
              if( response.data === "UPDATED" ){
                action = "updated";
              }

              Swal.fire("Good job!", "Payment is successfully "+action+"!", "success");
              app.$router.push({ name: 'payments' });
            }
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="savePayments">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="id_company">{{$t('menuitems.payments.list.company')}}</label>
                    <select
                      class="form-control"
                      name="id_company"
                      id="id_company"
                      v-model="form.id_company"
                      :class="{ 'is-invalid': submitted && $v.form.id_company.$error }"
                    >
                      <option value="">{{$t('menuitems.payments.list.selectCompany')}}</option>
                      <option v-for="(company, index) in companies" :key="index" :value="company.id_company">{{company.nm_company}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_company.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_company.required">Company is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_suscriptionType">{{$t('menuitems.management.list.suscriptionType')}}</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_suscriptionType.$error }"
                      name="id_suscriptionType"
                      id="id_suscriptionType"
                      v-model="form.id_suscriptionType"
                    >
                      <option value="">{{$t('menuitems.management.list.selectSuscriptionType')}}</option>
                      <option value="0">{{$t('menuitems.management.list.small')}}</option>
                      <option value="1">{{$t('menuitems.management.list.medium')}}</option>
                      <option value="2">{{$t('menuitems.management.list.high')}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_suscriptionType.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_suscriptionType.required">Suscription type is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_paymentType">{{$t('menuitems.payments.list.paymentType')}}</label>
                    <select
                      class="form-control"
                      name="id_paymentType"
                      id="id_paymentType"
                      v-model="form.id_paymentType"
                      :class="{ 'is-invalid': submitted && $v.form.id_paymentType.$error }"
                    >
                      <option value="">{{$t('menuitems.payments.list.selectPaymentType')}}</option>
                      <option value="0">{{$t('menuitems.payments.list.cash')}}</option>
                      <option value="1">{{$t('menuitems.payments.list.transfer')}}</option>
                      <option value="2">{{$t('menuitems.payments.list.check')}}</option>
                      <option value="3">{{$t('menuitems.payments.list.credit')}}</option>
                      <option value="4">{{$t('menuitems.payments.list.debit')}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_paymentType.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_paymentType.required">Labor type is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_totalPayment">{{$t('menuitems.payments.list.totalPayment')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_totalPayment.$error }"
                      name="num_totalPayment"
                      id="num_totalPayment"
                      v-model="form.num_totalPayment"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_totalPayment.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_totalPayment.required">Total Payment is required.</span>
                      <span v-if="!$v.form.num_totalPayment.minValue">The minimum payment is invalid. Minimum payment should be greather than $0.00 .</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="dropzone">{{$t('menuitems.pors.list.uploadTicket')}}</label>
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>{{$t('menuitems.pors.list.uploadAd')}}</h4>
                      </div>
                    </vue-dropzone>
                    
                    <button v-if="form.url_invoice" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      {{$t('menuitems.pors.list.downloadTicket')}}
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_invoice" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" @click="openTicket">
                      {{$t('menuitems.pors.list.viewTicket')}}
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.payments.list.save')}}</button>
                    <router-link to="/finances/payments" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.payments.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>