<script>
import Swal from "sweetalert2";
import axios from 'axios';

/**
 * Widget Component
 */
export default {
  props:{
    user: Object,
    initialDate: String,
    finalDate: String
  },
  data() {
    return {
      componentKey : 0,
      statData: [
        {
          title: "New Orders",
          icon: "far fa-file",
          value: "1452",
          subvalue: ""
        },
        {
          title: "In progress Orders",
          icon: "fas fa-play",
          value: "38452",
          subvalue: ""
        },
        {
          title: "Completed Orders",
          icon: "fas fa-check-double",
          value: "15",
          subvalue: ""
        }
      ]
    };
  },
  watch:{
    "initialDate": function(newValue){
      newValue;
      this.componentKey++;
    },
    "$parent.$i18n.locale": function(newValue){
      this.statData[0].title = this.$parent.$i18n.messages[newValue].menuitems.finances.list.newOrders;
      this.statData[1].title = this.$parent.$i18n.messages[newValue].menuitems.finances.list.inProgressOrders;
      this.statData[2].title = this.$parent.$i18n.messages[newValue].menuitems.finances.list.completedOrders;
    }
  },
  mounted() {
    this.getStatusQuantity();
    
  },
  methods: {
    getStatusQuantity(){
      let app = this;
      Swal.fire({
        title: "Loading data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/workorders.php',
        {
          params: {
            request: 'getStatusQuantity',
            id:this.$route.params.id,
            token: this.user.token,
            initialDate: this.initialDate,
            finalDate: this.finalDate
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.statData[0].value = response.data[0];
            app.statData[1].value = response.data[1];
            app.statData[2].value = response.data[2];

            app.componentKey ++;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    }
  }
};
</script>

<template :key="componentKey">
  <div class="row">
    <div class="col-md-12" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-2">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-check"></i>
              {{data.subvalue}}
            </span>
            <span class="text-muted ml-2">{{$t('menuitems.finances.list.totalCurrentlyOrders')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>