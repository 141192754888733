<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from "axios";

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Schedule registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  props:{
    id:[Number,null],
    member:[Object, null]
  },
  data() {
    return {
      title: "",
      componentKey: 0,
      items: [
        {
          text: "Performance",
          active: true
        },
      ],
      performances: [],
      form: {
        id_member: "",
        num_diameter: 0,
        num_height: 0,
        num_longitude: 0,
        num_weight: 0,
        num_size: 0,
      },
      submitted: false,
      validToken : true,
    };
  },
  validations: {
    form: {
      num_diameter:   { required },
      num_height:     { required },
      num_longitude:  { required },
      num_weight:     { required },
      num_size:       { required },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    savePerformance(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){

        Swal.fire({
          title: "Saving performance.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        //Setting the ID of the Member to the form object
        this.form.id_member = this.$route.params.id;

        axios.post(window.serverPath+'/dist/mvw/controllers/performances.php', {
          request: 'savePerformance',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.status == 200 ){
            Swal.close();
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              Swal.fire("Good job!", "Performance saved.", "success")
              .then(function(){
                app.$router.push({ name: 'memberEdit', params: { id: app.form.id_member } });
              })
              
            }
          }else{
            Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="savePerformance">
              <div class="row">
                <div class="col-sm-12">
                  <h4 class="card-title">
                    {{ $t('menuitems.members.list.physicalData') }}
                    for {{$route.params.member.nm_member + " " + $route.params.member.nm_lastName}}
                  </h4>
                  <div class="form-group">
                    <label for="num_diameter">{{ $t('menuitems.members.list.diameter') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_diameter"
                      id="num_diameter"
                      v-model="form.num_diameter"
                      value=""
                      step="any"
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_height">{{ $t('menuitems.members.list.height') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_height"
                      id="num_height"
                      v-model="form.num_height"
                      value=""
                      step="any"
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_longitude">{{ $t('menuitems.members.list.longitude') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_longitude"
                      id="num_longitude"
                      v-model="form.num_longitude"
                      value=""
                      step="any"
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_weight">{{ $t('menuitems.members.list.weight') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_weight"
                      id="num_weight"
                      v-model="form.num_weight"
                      value=""
                      step="any"
                    >
                  </div>
                  <div class="form-group">
                    <label for="num_size">{{ $t('menuitems.members.list.size') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="num_size"
                      id="num_size"
                      v-model="form.num_size"
                      value=""
                      step="any"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.members.list.savePerformance')}}</button>
                    <router-link to="/schedules" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.members.list.cancel')}} </button>
                    </router-link>
                  </div>
                </div>
              </div>
          </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>