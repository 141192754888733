<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
//import RevenueAnalytics from "./revenue";
//import SalesAnalytics from "./sales-analytics";
//import EarningReport from "./earning";
//import Sources from "./sources";
//import RecentActivity from "./recent-activity";
//import RevenueLocation from "./revenue-location";
//import Chat from "./chat";
//import Transaction from './transaction';


/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    //RevenueAnalytics,
    //SalesAnalytics,
    //EarningReport,
    //Sources,
    //RecentActivity,
    //RevenueLocation,
    //Chat,
    //Transaction
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Home"
        },
        {
          text: "Dashboard",
          active: true
        }
      ]
    };
  },
  mounted(){
    //this.createSegment();
  },
  methods:{
    testMessage() {
      const url = 'https://api.onesignal.com/notifications?c=push';
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: 'Key os_v2_app_ikaopipcqncpdbg5d5v34mcni32blioq2jae23vjxfoi4rjo3ir4iecoupmfy5v6aiiuj2kzzojykqb3tl4f4mi42fqscjj4rl7nngq',
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          app_id: '4280e7a1-e283-44f1-84dd-1f6bbe304d46',
          contents: {en: 'Your message body here.'},
          headings: {en: 'Trying messages from FETCH'},
          subtitle: {en: 'Next, try from axios'},
          small_icon: "ic_notification_icon",
          include_player_ids: ['20c6c91f-ac1b-4f51-a17a-f7d3cf9cab55']
        })
      };

      fetch(url, options)
        .then(res => res.json())
        .then(json => console.log(json))
        .catch(err => console.error(err));
    },

    createSegment(){
      const url = 'https://api.onesignal.com/apps/4280e7a1-e283-44f1-84dd-1f6bbe304d46/segments';
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: 'Key Ys_v2_app_ikaopipcqncpdbg5d5v34mcni32blioq2jae23vjxfoi4rjo3ir4iecoupmfy5v6aiiuj2kzzojykqb3tl4f4mi42fqscjj4rl7nngq',
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: '{"name":"GILBERTO_MARTINEZ"}'
      };

      fetch(url, options)
        .then(res => res.json())
        .then(json => console.log(json))
        .catch(err => console.error(err));
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <Stat />
        <!--<RevenueAnalytics />-->
      </div>
      <!--
      <div class="col-xl-4">
        <SalesAnalytics />
        <EarningReport />
      </div>
      -->
    </div>
    <!--
    <div class="row">
      <div class="col-lg-4">
        <Sources />
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
      <div class="col-lg-4">
        <RevenueLocation />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <Chat />
      </div>
      <div class="col-lg-8">
        <Transaction />
      </div>
    </div>
    -->
  </Layout>
</template>