<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script type="text/javascript" src="cordova.js"></script>
<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted(){
    document.addEventListener("offline", this.onOffline, false);
    document.addEventListener("online", this.onOnline, false);
    document.addEventListener("deviceready", this.onDeviceReady, false);

  },
  methods:{
    async onDeviceReady(){
      let platform = device.platform;

      if(platform){
        let anytimeLaunched = localStorage.getItem('anytimeLaunched');

        // Remove this method to stop OneSignal Debugging
        //window.plugins.OneSignal.Debug.setLogLevel(6);

        // Uncomment to set OneSignal visual logging to VERBOSE
        // window.plugins.OneSignal.Debug.setAlertLevel(6);

        // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
        window.plugins.OneSignal.initialize("4280e7a1-e283-44f1-84dd-1f6bbe304d46");

        //Adds an event listener for clicks on notifications
        const listener = (event) => {
            const notificationPayload = JSON.stringify(event);
            console.log(notificationPayload);
        };

        window.plugins.OneSignal.Notifications.addEventListener("click", listener);
      
        //Prompts the user for notification permissions.
        //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
         window.plugins.OneSignal.Notifications.requestPermission(true).then((accepted) => {
            console.log("User accepted notifications: " + accepted);
        });

        if(!anytimeLaunched){
          this.$router.push('/onboarding').catch((e) => e);
        }
      }
    },

    onOffline(){
      this.$bvToast.toast(
        'Seems like you have no internet connection.',
        {
          title: 'Internet connection',
          variant: 'warning',
          solid: true
        }
      );
    },

    onOnline(){
      this.$bvToast.toast(
        "You're connected to internet again.",
        {
          title: 'Internet connection',
          variant: 'info',
          solid: true
        }
      );
    }
  }
};
</script>