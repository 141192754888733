<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from 'axios';

import "export-json-excel";

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Group registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Group registration",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Groups",
          href: "#/groups"
        },
        {
          text: "Group",
          active: true
        }
      ],

      load:                 0,
      pools:                [],
      coaches:              [],
      schedules:            [],
      members:              [],
      selectedMembers:      [],
      filterOn:             [],
      filter:               null,
      membersFields:        ['name', 'age', 'gender', 'action'],
      membersSearch:        ['name', 'age', 'gender'],
      excelFields:          [
        {"title": "", "name": ""},
      ],

      selectedPool:         null,
      selectedCoach:        null,
      selectedSchedule:     null,

      form: {
        nm_group:           "",
        id_pool:            "",
        //nm_pool:            "",
        id_coach:           "",
        //nm_coach:           "",
        id_team:            "",
        //nm_team:            "",
        id_schedule:        "",
        //de_weekDays:        "",
        de_members:         [],
        num_minMembers:     0,
        num_maxMembers:     0,
        num_activeMembers:  0,
      },
      submitted: false,
      validToken: false,
    };
  },
  validations: {
    form: {
      id_pool:            { required },
      id_coach:           { required },
      id_schedule:        { required },
      num_minMembers:     { required },
      num_maxMembers:     { required },
    }
  },
  computed:{
    freeSpace: function(){
      return (parseInt(this.form.num_maxMembers) - this.selectedMembers.length);
    }
  },
  watch:{
    load: function(oldValue, newValue){
      if(newValue == 4){
        this.searchForItem('pools', 'pool', this.form.id_pool, 'selectedPool');
        this.searchForItem('coaches', 'coach', this.form.id_coach, 'selectedCoach');
        this.searchForItem('schedules', 'schedule', this.form.id_schedule, 'selectedSchedule');
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.getPools();
    this.getCoaches();
    this.getSchedules();
    this.getMembers();
    
    if( this.$route.params.id ){
      this.getGroupByID();
    }
  },
  methods: {
    searchForItem(variable, obj, idItem, destiny){
      let app = this;
      this[variable].forEach(function(item){
        if( idItem == item['id_'+obj] ){
          app[destiny] = item;
        }
      });
    },

    async getGroupByID(){
      let app = this;
      Swal.fire({
        title: "Loading group data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/groups.php',
        {
          params: {
            request: 'getGroupByID',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            this.validToken = false;
          }else{
            this.form.id_pool         = response.data.id_pool;
            this.form.id_coach        = response.data.id_coach;
            this.form.id_schedule     = response.data.id_schedule;
            this.form.id_team         = response.data.id_team;
            this.form.nm_group        = response.data.nm_group;
            this.form.de_members      = response.data.de_members;
            this.form.num_minMembers  = response.data.num_minMembers;
            this.form.num_maxMembers  = response.data.num_maxMembers;

            this.selectedMembers      = response.data.de_members;
            
            this.selectedMembers.forEach(function(member){
              app.members.forEach(function(m){
                if( m.id_member == member.id_member){
                  m['selected'] = true;
                  member['selected'] = true;
                  return;
                }
              });
            });

            this.load++;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load members data! Please load this module again.", "error");
        }
      });
    },

    async getPools(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/pools.php',
        {
          params: {
            request: 'getPools',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(response.data == 'Invalid token'){
            Swal.close();
            this.$bvModal.show('loginModal');
          }else{
            this.pools = response.data;
            this.load++;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load pools data! Please load this module again.", "error");
        }
      });
    },

    async getCoaches(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/coaches.php',
        {
          params: {
            request: 'getCoaches',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.coaches = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load coaches data! Please load this module again.", "error");
        }
      });
    },

    async getSchedules(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/schedules.php',
        {
          params: {
            request: 'getSchedules',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.schedules = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load schedules data! Please load this module again.", "error");
        }
      });
    },

    async getMembers(){
      Swal.fire({
        title: "Loading members data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/members.php',
        {
          params: {
            request: 'getMembers',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.members = response.data;

          this.members.forEach(function(member, index, object){
            if(member.id_group != "" || member.id_group != null){
              object.splice(index, 1);
            }
          });

          this.load++;
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load members data! Please load this module again.", "error");
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    saveGroup(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      this.form.id_pool           = this.selectedPool.id_pool;
      //this.form.nm_pool           = this.selectedPool.nm_pool;
      this.form.id_coach          = this.selectedCoach.id_coach;
      //this.form.nm_coach          = this.selectedCoach.nm_coach + " " + this.selectedCoach.nm_lastName;
      this.form.id_schedule       = this.selectedSchedule.id_schedule;
      //this.form.de_weekDays       = this.selectedSchedule.days + " " + this.selectedSchedule.schedule;
      this.form.num_activeMembers = this.selectedMembers.length;
      this.form.de_members        = this.selectedMembers;
      

      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_group = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving group data.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/groups.php', {
          request: 'saveGroup',
          data: this.form,
            token: this.user.token,
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "The group is correct "+action+"!", "success")
          .then(function(response){
            if(response.isConfirmed){
              app.$router.push({ name: 'groups' });
            }
          });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },

    onFiltered(filteredItems) {
      //let app   = this;
      let rows  = filteredItems.length;

      //Marking on green the spare parts or services of the workorder
      for( let i=0; i<rows; i++){
        if(filteredItems[i].selected){
          //app.$refs['selectableTable'].selectRow(i);
          this.tbodyRowClass(filteredItems[i]);
        }
      }
    },

    rowClicked(item) {
      let app = this;

      if(!item._rowVariant){
        if(item.selected){
          let index = 0;
          console.log('selected');

          this.$set(item, 'selected', false);
          delete item._rowVariant;
          
          this.selectedMembers.forEach(function(member){
            if(member.id_member == item.id_member){
              app.selectedMembers.splice(index, 1);
            }
            index ++;
          });

        }else{
          item.id_group     = this.$route.params.id;
          item.id_schedule  = this.form.id_schedule;
          console.log(item);
          this.$set(item, 'selected', true);
          this.selectedMembers.push(item);
        }
      }
    },

    tbodyRowClass(item) {
      /* Style the row as needed */
      if(item.selected){
        //return ["b-table-row-selected", "table-primary", "cursor-pointer"]
        return ["b-table-row-selected", "table-active"];
      }
    },

    removeMember(member){
      let app = this;

      Swal.fire({
        title: "Wait!",
        text: "Are you sure to remove this member from the group?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      })
      .then(function(response){
        if(response.isConfirmed){
          Swal.fire({
            title: "Removing",
            showConfirmButton:false,
            onBeforeOpen: () => {
              Swal.showLoading();
            }
          });

          var data = {
            id_member: member.id_member,
            id_group: null,
          };

          if(member.id_group != null ){
            axios.post(window.serverPath+'dist/mvw/controllers/members.php', {
              request: 'saveMember',
              data: data,
              token: app.user.token,
            })
            .then(function (response) {
              if( response.status == 200 ){
                Swal.close();

                Swal.fire("Good job!", "Member removed from this group!", "success");
              }else{
                Swal.fire("Ooops", "There was an error trying to remove this member from this group. Please try again.", "error");
              }
            })
            .catch(function (error) {
              Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
            });
          }
        }

        let index = 0;

        app.selectedMembers.forEach(function(item){
          if(item.id_member == member.id_member){
            app.selectedMembers.splice(index, 1);
          }
          index ++;
        });

        index = 0;
        app.members.forEach(function(item){
          if(item.id_member == member.id_member){
            app.members[index].selected = false;
            delete app.selectedMembers._rowVariant;
          }
          index ++;
        });

        Swal.close();
        Swal.fire("Good job!", "Member removed from this group!", "success");
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <!--<form class="needs-validation" @submit.prevent="">-->
              <div class="row">
                  <div class="col-sm-7">
                    <h4 class="card-title">
                      {{ $t('menuitems.groups.list.groupData') }} (
                      <b-badge variant="success">{{freeSpace}} place(s) available(s)</b-badge>
                      )
                    </h4>
                    <div class="form-group">
                      <label for="nm_group">{{ $t('menuitems.groups.list.groupName') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        name="nm_group"
                        id="nm_group"
                        v-model="form.nm_group"
                        value=""
                      >
                    </div>

                    <div class="form-group">
                      <label for="id_pool">{{ $t('menuitems.groups.list.pool') }}</label>
                      <select
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.id_pool.$error }"
                        name="id_pool"
                        id="id_pool"
                        v-model="selectedPool"
                      >
                        <option value="">SELECT POOL</option>
                        <option v-for="(pool, index) in pools" :key="index" :value="pool">{{pool.nm_pool}}</option>-->
                      </select>
                      <div v-if="submitted && $v.form.id_pool.$error" class="invalid-feedback">
                        <span v-if="!$v.form.id_pool.required">Pool is required.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="id_coach">{{ $t('menuitems.groups.list.coach') }}</label>
                      <select
                        class="form-control"
                        name="id_coach"
                        id="id_coach"
                        v-model="selectedCoach"
                        :class="{ 'is-invalid': submitted && $v.form.id_coach.$error }"
                      >
                        <option value="">SELECT COACH</option>
                        <option v-for="(coach, index) in coaches" :key="index" :value="coach">{{coach.nm_coach + ' ' + coach.nm_lastName}}</option>-->
                      </select>
                      <div v-if="submitted && $v.form.id_coach.$error" class="invalid-feedback">
                        <span v-if="!$v.form.id_coach.required">Coach is required.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="id_schedule">{{ $t('menuitems.groups.list.schedule') }}</label>
                      <select
                        class="form-control"
                        name="id_schedule"
                        id="id_schedule"
                        v-model="selectedSchedule"
                        :class="{ 'is-invalid': submitted && $v.form.id_schedule.$error }"
                      >
                        <option value="">SELECT SCHEDULE</option>
                        <option v-for="(schedule, index) in schedules" :key="index" :value="schedule">{{schedule.days + ' ' + schedule.schedule}}</option>
                      </select>
                      <div v-if="submitted && $v.form.id_schedule.$error" class="invalid-feedback">
                        <span v-if="!$v.form.id_schedule.required">Schedule is required.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="num_minMembers">{{ $t('menuitems.groups.list.minimum') }}</label>
                      <input
                        type="number"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.num_minMembers.$error }"
                        name="num_minMembers"
                        id="num_minMembers"
                        v-model="form.num_minMembers"
                        value=""
                      >
                      <div v-if="submitted && $v.form.num_minMembers.$error" class="invalid-feedback">
                        <span v-if="!$v.form.num_minMembers.required">The minimum number of members is required.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="num_maxMembers">{{ $t('menuitems.groups.list.maximum') }}</label>
                      <input
                        type="number"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.num_maxMembers.$error }"
                        name="num_maxMembers"
                        id="num_maxMembers"
                        v-model="form.num_maxMembers"
                        value=""
                      >
                      <div v-if="submitted && $v.form.num_maxMembers.$error" class="invalid-feedback">
                        <span v-if="!$v.form.num_maxMembers.required">The maximum number of members is required.</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="row">
                      <div class="col-sm-6"><h5 class="card-title">{{ $t('menuitems.groups.list.groupMembers') }}</h5></div>
                      <div class="col-sm-6 text-right">
                        <span class="btn btn-success btn-sm" v-b-modal.modal-members><i class="fas fas fa-plus"></i> {{ $t('menuitems.groups.list.add') }}</span>
                        <export-json-excel
                          v-if="selectedMembers.length > 0"
                          :data="selectedMembers"
                          class="btn btn-sm btn-outline-dark ml-2"
                        >
                          <span title="Download spreadsheet report">
                            <b-badge variant="dark"><i class="fas fa-download"></i></b-badge>
                            <b-badge variant="dark" class="ml-1"><i class="fas fa-file-excel"></i></b-badge>
                          </span>
                        </export-json-excel>
                      </div>
                    </div>

                    <b-table
                      class="mt-3"
                      sticky-header
                      small
                      :items="selectedMembers"
                      :fields="membersFields"
                      responsive="sm"
                      style="max-height: 450px !important;"
                      :tbody-tr-class="tbodyRowClass"
                    >
                    <template v-slot:cell(action)="row">
                      <button type="button" size="sm" class="mr-1 btn btn-sm btn-danger" @click="removeMember(row.item)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                      <!-- info(row.item, row.index, $event.target) -->
                    </template>
                    </b-table>
                  </div>
              </div>

              <div class="row">
                <b-modal no-close-on-backdrop
                  id="modal-members"
                  :title="$t('menuitems.groups.list.search')"
                  title-class="font-18"
                  centered
                >
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <b-form-group
                          :label="$t('menuitems.groups.list.filter')"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="left"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              :placeholder="$t('menuitems.groups.list.typeToSearch')"
                            ></b-form-input>

                            <b-input-group-append>
                              <b-button :disabled="!filter" @click="filter = ''">{{ $t('menuitems.groups.list.clear') }}</b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </div>

                      <b-table
                        sticky-header
                        small
                        :items="members"
                        :fields="membersSearch"
                        responsive="sm"
                        selectable
                        ref="selectableTable"
                        @row-clicked="rowClicked"
                        style="max-height: 450px !important;"
                        @filtered="onFiltered"
                        :filter="filter"
                        :tbody-tr-class="tbodyRowClass"
                      >
                        <!-- Example scoped slot for select state illustrative purposes 
                        <template #cell(shop_price)="data">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control-sm"
                                name=""
                                id=""
                                :value="data.value"
                                size="5"
                                @change="setShopPrice($event, data.index)"
                              >
                            </div>
                        </template>
                        -->
                      </b-table>
                    </div>
                  </div>
                </b-modal>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="button" @click="saveGroup">{{ $t('menuitems.groups.list.save') }}</button>
                    <router-link to="/groups" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{ $t('menuitems.groups.list.cancel') }}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            <!--</form>-->
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-input-tag-wrapper {
    height: auto !important;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #5664d2 !important;
    border: 1px solid #5664d2 !important;
    color: #fff !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff !important;
  }
</style>