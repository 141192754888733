<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Basic from "./basic";
import axios from "axios"
import error403 from '../utility/error-403';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Basic, error403 },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      info: null,
      title: "",
      componentKey: 0,
      num_latitude: 0.0,
      num_longitude: 0.0,
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Sites",
          href: "/company/sites"
        },
        {
          text: "Site",
          active: true
        }
      ],
      companies: [],
      countries: [],
      states: [],
      cities: [],
      form: {
        id_company: "",
        id_country: "",
        id_state: "",
        de_addressSite: "",
        de_siteRepresentantEmail: "",
        de_siteHumanResourcesEmail: "",
        de_siteContactEmail: "",
        nm_site: "",
        num_latitude: 0,
        num_longitude: 0,
        yn_isCorporativeSite: false
      },
      submitted: false,
      validToken: true,
      validAccess: true,
      user:         {},
    };
  },
  validations: {
    form: {
      id_company: { required },
      id_country: { required },
      id_state: { required },
      de_addressSite: { required, minLength: minLength(20), maxLength: maxLength(200) },
      de_siteRepresentantEmail: { required, email },
      de_siteHumanResourcesEmail: { required, email },
      de_siteContactEmail: { required, email },
      nm_site: {required, minLength: minLength(2), maxLength: maxLength(50)}
    }
  },
  watch:{
    lat(newLat, oldLat){
      if(newLat != oldLat){
        this.lat = newLat;
        this.forceRerenderMap();
      }
    },
    lng(newLng, oldLng){
      if(newLng != oldLng){
        this.lng = newLng;
        this.forceRerenderMap();
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.getCompanies();
    this.getCountries();

    if( this.$route.params.id ){
      this.getSite();
    }
  },
  methods: {
    getCompanies(){
      Swal.fire({
        title: "Loading Companies",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/organizations.php',
        {
          params: {
            request: 'getOrganizationsForCombos',
            idCompany: this.user.user.id_company,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.companies = response.data;
            app.form.id_company = app.user.user.id_company;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },
    getCountries(){
      Swal.fire({
        title: "Loading Countries",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/countries.php',
        {
          params: {
            request: 'getCountries',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.countries = response.data;
            Swal.close();
            if(this.$route.params.id){
              this.getStatesByCountryID();
            }
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    getStatesByCountryID(){
      let app = this;
      Swal.fire({
        title: "Loading States",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/states.php',
        {
          params: {
            request: 'getStatesByCountryID',
            idCountry: this.form.id_country,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if(response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if(response.status == 404){ app.$router.push({ name: 'Error-404' }); }
        if(response.status == 401){ app.$router.push({ name: 'Error-401' }); }

        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.states = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    getSite(){
      Swal.fire({
        title: "Loading site.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSite',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.site = response.data;

            this.form.id_country                    = app.site.id_country;
            this.form.id_state                      = app.site.id_state;
            this.form.de_addressSite                = app.site.de_addressSite;
            this.form.de_siteRepresentantEmail      = app.site.de_siteRepresentantEmail;
            this.form.de_siteHumanResourcesEmail    = app.site.de_siteHumanResourcesEmail;
            this.form.de_siteContactEmail           = app.site.de_siteContactEmail;
            this.form.nm_site                       = app.site.nm_site;

            this.form.num_latitude                  = app.site.num_latitude;
            this.form.num_longitude                 = app.site.num_longitude;

            this.num_latitude                       = app.site.num_latitude;
            this.num_longitude                      = app.site.num_longitude;

            if(app.site.yn_isCorporativeSite == 1){
              this.form.yn_isCorporativeSite        = true;  
            }else{
              this.form.yn_isCorporativeSite        = false;
            }
            Swal.close();
            this.getCountries();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveSite(e) {
      let app   = this;
      let array = [1,2,12];

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        this.form.id_company = this.user.id_company;
        
        if( this.$route.params.id ){
          this.form.id_site = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving site.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if( !array.includes( this.user.user.id_role ) ){
          Swal.fire("Privileges error!", "You do not have privileges to add/modify organizations.", "error");
        }else{
          axios.post(window.serverPath+'/dist/mvw/controllers/sites.php', {
            request: 'saveSite',
            data: this.form,
            token: this.user.token,
          })
          .then(function (response) {
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              let action = "";
              if( response.data === "OK" ){
                action = "saved";
              }
              if( response.data === "UPDATED" ){
                action = "updated";
              }

              Swal.fire("Good job!", "Your site is correct "+action+"!", "success");
              app.$router.push({ name: 'sites' });
            }
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }
    },
    setPosition(event){
      this.form.num_latitude = event.lat;
      this.form.num_longitude = event.lng;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveSite">
              <div class="row">
                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.sites.list.locationData')}}</h4>
                  <p class="card-title-desc">{{$t('menuitems.sites.list.locationDataDescription')}}</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_company">{{$t('menuitems.sites.list.company')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_company.$error }"
                          name="id_company"
                          id="id_company"
                          v-model="form.id_company"
                        >
                          <option value="">{{$t('menuitems.sites.list.selectCompany')}}</option>
                          <option v-for="(company, index) in companies" :key="index" :value="company.id_company">{{company.nm_company}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_company.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_company.required">Company field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_country">{{$t('menuitems.sites.list.country')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_country.$error }"
                          name="id_country"
                          id="id_country"
                          v-model="form.id_country"
                          @change="getStatesByCountryID"
                        >
                          <option value="">{{$t('menuitems.sites.list.selectCountry')}}</option>
                          <option value="142">México</option>
                          <option value="231">United States</option>
                          <hr>
                          <option v-for="(country, index) in countries" :key="index" :value="country.id_country">{{country.nm_country}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_country.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_country.required">Country field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_state">{{$t('menuitems.sites.list.state')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_state.$error }"
                          name="id_state"
                          id="id_state"
                          v-model="form.id_state"
                          value=""
                        >
                          <option value="">{{$t('menuitems.sites.list.selectState')}}</option>
                          <option v-for="(state, index) in states" :key="index" :value="state.id_state">{{state.nm_state}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_state.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_state.required">State/Province field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_addressSite">{{$t('menuitems.sites.list.address')}}</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_addressSite.$error }"
                          name="de_addressSite"
                          id="de_addressSite"
                          v-model="form.de_addressSite"
                          rows="3"
                          value=""
                        >
                        </textarea>
                        <div v-if="submitted && $v.form.de_addressSite.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_addressSite.required">Address field is required.</span>
                          <span v-if="!$v.form.de_addressSite.minLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                          <span v-if="!$v.form.de_addressSite.maxLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="num_latitude">{{$t('menuitems.sites.list.latitude')}}</label>
                            <input
                              class="form-control"
                              name="num_latitude"
                              id="num_latitude"
                              v-model="form.num_latitude"
                              type="text"
                              placeholder="Enter latitude coordenate"
                              value=""
                            />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="num_longitude">{{$t('menuitems.sites.list.longitude')}}</label>
                            <input
                              class="form-control"
                              name="num_longitude"
                              id="num_longitude"
                              v-model="form.num_longitude"
                              type="text"
                              placeholder="Enter longitude coordenate"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.sites.list.contactData')}}</h4>
                  <p class="card-title-desc">{{$t('menuitems.sites.list.contactDataDescription')}}</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="de_siteRepresentantEmail">{{$t('menuitems.sites.list.representantEmail')}}</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_siteRepresentantEmail.$error }"
                          name="de_siteRepresentantEmail"
                          id="de_siteRepresentantEmail"
                          v-model="form.de_siteRepresentantEmail"
                          value=""
                        >
                        <div v-if="submitted && $v.form.de_siteRepresentantEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_siteRepresentantEmail.required">Representant email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_siteHumanResourcesEmail">{{$t('menuitems.sites.list.humanResourcesEmail')}}</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_siteHumanResourcesEmail.$error }"
                          name="de_siteHumanResourcesEmail"
                          id="de_siteHumanResourcesEmail"
                          v-model="form.de_siteHumanResourcesEmail"
                          value=""
                        >
                        <div v-if="submitted && $v.form.de_siteHumanResourcesEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_siteHumanResourcesEmail.required">Human Resources email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_siteContactEmail">{{$t('menuitems.sites.list.contactEmail')}}</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_siteContactEmail.$error }"
                          name="de_siteContactEmail"
                          id="de_siteContactEmail"
                          v-model="form.de_siteContactEmail"
                          value=""
                        >
                        <div v-if="submitted && $v.form.de_siteContactEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_siteContactEmail.required">Contact email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_site">{{$t('menuitems.sites.list.siteName')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_site.$error }"
                          name="nm_site"
                          id="nm_site"
                          v-model="form.nm_site"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_site.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_site.required">Site name is required.</span>
                          <span v-if="!$v.form.de_addressSite.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.de_addressSite.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <b-form-checkbox v-model="form.yn_isCorporativeSite" switch class="mb-1">
                          <label>{{$t('menuitems.sites.list.isCorporative')}}</label>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title mb-4">{{$t('menuitems.sites.list.siteLocation')}}</h4>
                      <Basic :lat.sync="num_latitude" :lng.sync="num_longitude" :key="componentKey" @changeMarker="setPosition"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.sites.list.save')}}</button>
                    <router-link to="/company/sites" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.sites.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>