<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from "axios"
import Multiselect from "vue-multiselect";

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Set registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      info: null,
      title: "",
      componentKey: 0,
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Sets",
          href: "#/sets"
        },
        {
          text: "Set",
        }
      ],
      exercises: [],
      periods: ["Seconds (s)", "Minutes (m)", "Hours (h)", "Times (t), Metters (mts)"],
      weekDays:   ["MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"],
      meassures: ["Serie", "Round", "Time"],
      times: ["Seconds (s)", "Minutes (m)", "Hours (h)"],
      units: ["Kilograms (kg)", "Metters (mts)", "Miles (mi)", "Kilometters (kms)", "Reps (r)"],
      validVideo: false,
      validToken: true,
      form: {
        nm_set: '',
        de_setDescription: '',
        selectedExercises: [],
        //selectedDays: [],
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      nm_set:             { required },
      de_setDescription:  { required },
    }
  },
  watch:{
    validToken(newValue){
      console.log(newValue);
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    let app     = this;
    let index   = 0;
    let select  = document.getElementById("num_weekDays");

    //EventListener of language change. If changed, then update the days of the week from EN to ES and viceversa
    window.addEventListener('language-changed', (event) => {
      if(event.detail.language == 'es'){
        app.num_weekDays = ["LUNES","MARTES","MIERCOLES","JUEVES","VIERNES","SABADO","DOMINGO"];
      }else{
        app.num_weekDays = ["MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"];
      }
      //Clearing the options
      select.options.length = 0;

      app.num_weekDays.forEach(function(day){
        //Adding new options
        select.options[select.options.length] = new Option(day, index);
        index++;
      });
      index = 0;
    });

    this.getExercises();

    if( this.$route.params.id ){
      this.getSetByID();
    }
  },
  methods: {
    async getExercises(){
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/exercises.php',
        {
          params: {
            request: 'getExercisesForMultipleSelect',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(Array.isArray(response.data)){
            app.exercises = response.data;
          }
          
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    async getSetByID(){
      Swal.fire({
        title: "Loading set data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/sets.php',
        {
          params: {
            request: 'getSetByID',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          app.set = response.data;

          this.form.id_set             = app.set.id_set;
          this.form.de_setDescription  = app.set.de_setDescription;
          this.form.nm_set             = app.set.nm_set;
          this.form.selectedExercises  = JSON.parse(app.set.de_selectedExercises);
          //this.form.selectedDays       = JSON.parse(app.set.de_selectedDays);
          
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveSet(e) {
      let app = this;

      e.preventDefault();

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_set = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving set.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/sets.php', {
          request: 'saveSet',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your set is correct "+action+"!", "success");
          app.$router.push({ name: 'sets' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
    setPosition(event){
      this.form.num_latitude = event.lat;
      this.form.num_longitude = event.lng;
    },
    validateYouTubeUrl(e){
      var url = e.target.value;
      if (url != undefined && url != '') {        
          var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
          var match = url.match(regExp);
          if (match && match[2].length == 11) {
            this.validVideo = true;
            var element = document.getElementById('youtubevideo');
            // Do anything for being valid
            // if need to change the url to embed url then use below line            
            element.src = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1';
          } else {
            this.validVideo = false;
            Swal.fire("Ooops", "This is not a valid youtube video, please verify the link.", "error");
            // Do anything for not being valid
          }
      }else{
        this.validVideo = false;
      }
    },
    setQuantity(event){
      console.log(event.target.value);
      this.form.selectedExercises[event.target.id].quantity = event.target.value;
      //console.log(this.selectedExercises(index).quantity);
    },
    printLog(index){
      console.log(this.form.selectedExercises[index]);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveSet">
              <div class="row">
                <div class="col-sm-4">
                  <h4 class="card-title">{{ $t('menuitems.sets.list.setData') }}</h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_set">{{ $t('menuitems.sets.list.name') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_set.$error }"
                          name="nm_set"
                          id="nm_set"
                          v-model="form.nm_set"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_set.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_set.required">Set name is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_setDescription">{{ $t('menuitems.sets.list.description') }}</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_setDescription.$error }"
                          name="de_setDescription"
                          id="de_setDescription"
                          v-model="form.de_setDescription"
                          rows="5"
                          value=""
                        >
                        </textarea>
                        <div v-if="submitted && $v.form.de_setDescription.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_setDescription.required">Description field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_materials">{{ $t('menuitems.sets.list.exercises') }}</label>
                        <multiselect
                          id="id_pools"
                          v-model="form.selectedExercises"
                          :options="exercises"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :placeholder="$t('menuitems.sets.list.selectExercises')"
                          label="label"
                          track-by="label"
                          :preselect-first="false"
                        ></multiselect>
                      </div>

                      <!--
                      <div class="form-group">
                        <label for="num_weekDays">{{$t('menuitems.schedules.list.days')}}</label>
                        <select
                          multiple
                          class="form-control"
                          name="num_weekDays"
                          id="num_weekDays"
                          v-model="form.selectedDays"
                          size="7"
                          ref="num_weekDays"
                        >
                          <option v-for="(day, index) in weekDays" :key="index" :value="index">{{day}}</option>
                        </select>
                      </div>
                      -->

                    </div>
                  </div>
                </div>

                <div class="col-sm-8" v-show="form.selectedExercises.length>0">
                  <h4 class="card-title">&nbsp;</h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table mb-0 table-bordered table-striped" :fixed="true" style="min-width: 300px !important;">
                          <thead class="thead-dark">
                            <tr>
                              <th>{{ $t('menuitems.sets.list.exercise') }}</th>
                              <th>{{ $t('menuitems.sets.list.quantity') }}</th>
                              <th>{{ $t('menuitems.sets.list.meassure') }}</th>
                              <th>{{ $t('menuitems.sets.list.time') }}</th>
                              <th>{{ $t('menuitems.sets.list.unit') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(exercise, index) in form.selectedExercises" :key="index">
                              <td scope="row" class="pt-3">{{exercise.label}}</td>
                              <td>
                                <input class="form-control-sm" type="number" v-model="form.selectedExercises[index].quantity" :id="index">
                              </td>
                              <td>
                                <select
                                  class="form-control-sm"
                                  name="de_meassure"
                                  id="de_meassure"
                                  v-model="form.selectedExercises[index].meassure"
                                >
                                  <option value="">{{ $t('menuitems.sets.list.selectMeassure') }}</option>
                                  <option v-for="(meassure, index) in meassures" :key="index" :value="meassure">{{meassure}}</option>
                                </select>
                              </td>
                              <td>
                                <input class="form-control-sm mb-1" placeholder="Enter time" type="number" v-model="form.selectedExercises[index].timesQuantity" :id="index">
                                <select
                                  class="form-control-sm"
                                  name="de_time"
                                  id="de_time"
                                  v-model="form.selectedExercises[index].time"
                                >
                                  <option value="">{{ $t('menuitems.sets.list.selectTime') }}</option>
                                  <option v-for="(time, index) in times" :key="index" :value="time">{{time}}</option>
                                </select>
                              </td>
                              <td>
                                <input class="form-control-sm mb-1" placeholder="Enter unit" type="number" v-model="form.selectedExercises[index].unitsQuantity" :id="index">
                                <select
                                  class="form-control-sm"
                                  name="de_unit"
                                  id="de_unit"
                                  v-model="form.selectedExercises[index].unit"
                                >
                                  <option value="">{{ $t('menuitems.sets.list.selectUnit') }}</option>
                                  <option v-for="(unit, index) in units" :key="index" :value="unit">{{unit}}</option>
                                </select>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{ $t('menuitems.sets.list.save') }}</button>
                    <router-link to="/sets" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{ $t('menuitems.sets.list.cancel') }}</button>
                    </router-link>
                  </div>
                </div>
              </div>
          </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    border: solid 1px!important;
    border-color:#b1bbc4 !important;
    width:100% !important;
}
</style>