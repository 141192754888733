<script type="text/javascript" src="cordova.js"></script>
<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import ReLogin from '../pages/account/re-login'
import axios from 'axios';

export default {
  components: { Vertical, Horizontal, ReLogin },
  data() {
    return {
      user: {},
      main: 'main',

      version:0,
      latestVersion:0,
      alreadyAccepted: false,

      position: null,

      tips: [
        {title:"¿Ya cambiaste el aceite a tu motor?", desc:"Con el tiempo, el aceite pierde sus propiedades lubricantes, por eso es importante cambiar."},
        {title:"¡Cambia tus bujías Ya!", desc:"Unas bujías desgastadas pueden causar dificultades para arrancar el motor, fallos de encendido, tirones y detonaciones."},
        {title:"No olvides revisar el nivel de agua.", desc:"Revisar el agua de tu auto o refrigerante te ayudará a evitar cualquier inconveniente y a mantener tu seguridad al volante."},
        {title:"¿Por qué revisar el nivel de anticongelante?", desc:"El anticongelante absorbe el exceso de calor producido por el motor, liberándolo posteriormente a través del radiador."},
        {title:"No dejes que tus frenos se estropeen", desc:"Hacer que un profesional los inspeccione puede ayudar a garantizar que todas las piezas funcionen como deberían."},
        {title:"Tip AutoShop", desc:"Lleva tu coche cada 200,000 kms a tu taller preferido para un mantenimiento de servicio mayor. Ayudará a mantenerlo como nuevo."},
        {title:"Alineación y balanceo", desc:"Si notas que tu auto va en una dirección diferente a pesar de que tienes el volante en horizontal, tal vez debas revisar la alineación y balanceo de tus llantas."},
        {title:"Cambio de filtro de aire", desc:"Los filtros de aire evitan que los desechos dañinos, como el polvo y otros contaminantes, entren en el motor y otros componentes claves."},
        {title:"Tip AutoShop", desc:"No cargar combustible hasta que el tanque esté vacío. Las partículas del fondo del tanque podrían entrar al sistema de combustión y tapar las mangueras."},
        {title:"Cambio de batería", desc:"Es una buena idea revisar la batería anualmente, más a menudo si nota signos de debilidad o si su batería tiene entre 3 y 5 años."},
        {title:"Tip AutoShop", desc:"Los talleres AutoShop, cuentan con pesonal y herramienta calificada para garantizar el buen funcionamiento de tu vehículo. Lleva tu coche a un taller AutoShop."}
      ],
    };
  },
  computed: {
    ...layoutComputed
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted(){
    var date = new Date();
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    onDeviceReady(){
      //Getting a random number between 0 and 9
      var position = this.randomIntFromInterval(0,9);

      //Clearing any pending notification of the app
      cordova.plugins.notification.local.clearAll();
      //Canceling any pending notification of the app.
      cordova.plugins.notification.local.cancelAll();
      //cordova.plugins.notification.local.on('trigger', function(notification){this.notification.title="ejemplo";}, this);

      this.getLatestVersion();

      /*
      //Sending a notification when user opens the app
      cordova.plugins.notification.local.schedule({
        at: this.date+50000,
        title: this.tips[position].title,
        text: this.tips[position].desc,
        icon:"https://autoshop.blueseal.me/dist/img/logo.609016d6.png",
        foreground: true,
        background: true,
      });

      
      var position = this.randomIntFromInterval(0,9);
      cordova.plugins.notification.local.schedule({
        title: this.tips[position].title,
        text: this.tips[position].desc,
        trigger: {every: { hour: 8, minute: 0 } },
        icon:"https://autoshop.blueseal.me/dist/img/logo.609016d6.png",
        foreground: true,
        background: true,
      });

      position = this.randomIntFromInterval(0,9);
      cordova.plugins.notification.local.schedule({
        title: this.tips[position].title,
        text: this.tips[position].desc,
        trigger: {every: { hour: 18, minute: 0 } },
        icon:"https://autoshop.blueseal.me/dist/img/logo.609016d6.png",
        foreground: true,
        background: true,
      });
      */
    },

    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    returnRandomTip(){
      return this.tips[this.randomIntFromInterval(0,9)];
    },

    closeModal(component){
      this.$bvModal.hide(component);
      this.$router.go();
    },

    async getLatestVersion(){
      let app       = this;

      let platform  = device.platform;
      let version   = (await ApkUpdater.getInstalledVersion());

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/versions.php',
        {
          params: {
            request: 'getVersions',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( !isNaN(response.data.num_code)){
            app.latestVersion = response.data.num_code;

            //alert(version.version.code + " - " + response.data.num_code);

            if(platform){
              //Clearing any pending notification of the app
              cordova.plugins.notification.local.clearAll();
              //Canceling any pending notification of the app.
              cordova.plugins.notification.local.cancelAll();
              //cordova.plugins.notification.local.on('trigger', function(notification){this.notification.title="ejemplo";}, this);
              
              if(version.version.code != response.data.num_code){
                navigator.notification.confirm(
                  "There is a new update of AutoShop. ¿Would you like to download and update it?",
                  app.updateFromDialog,
                  "AutoShop Update",
                  ["YES", "NO"]
                );

                //Sending a notification when user opens the app
                cordova.plugins.notification.local.schedule({
                  id: 0,
                  title: "AutoShop update.",
                  text: "There is a new update of AutoShop. ¿Would you like to download and update it?",
                  icon:"https://autoshop.blueseal.me/dist/img/logo.609016d6.png",
                  foreground: true,
                  background: true,
                  actions:[{id: 'yes', title: 'YES', launch: false},{id:'no',title:'NO', launch:false}]
                });

                cordova.plugins.notification.local.on("yes",function(notification){
                  if( !app.alreadyAccepted ){
                    app.update();
                  }else{
                    navigator.notification.alert("Update already accepted. There is nothing to do.");
                  }
                });

                cordova.plugins.notification.local.on("no",function(notification){
                  cordova.plugins.notification.local.clearAll();
                });
              }
            }
          }
        }
      });
    },

    updateFromDialog(indexButton){
      cordova.plugins.notification.local.clearAll();
      cordova.plugins.notification.local.cancelAll();
      
      if(indexButton == 1){
        this.alreadyAccepted = true;
        this.update();
      }
    },

    async update() {
      var progress = window.navigator.dialogsPlus.progressStart(
        "Downloading update.",
        "Please wait until download is completed, after that, you will be asked to install/update the application."
      );

      await ApkUpdater.download(
          'https://autoshop.blueseal.me/downloads/latest.apk',
          {
            onDownloadProgress: function(e){
              /*
              cordova.plugins.notification.local.schedule({
                title: 'Updating',
                text: 'Downloading update',
                androidProgressBar: {
                  value: e.progress,
                  maxValue: e.bytes,
                  indeterminate: false // Default false
                }
              });
              */
              progress.setValue(e.progress);
            }
          }
      );
      progress.hide(null, null);
      await ApkUpdater.install();
    },
  }
};
</script>

<template>
  <div>
    <b-modal
      centered
      hide-footer
      hide-header-close
      no-close-on-backdrop
      ref="newLogin"
      id="loginModal"
      title-class="font-18"
      title="Session expired. Please login"
    >
      <ReLogin
        :email="user.user.de_email"
        @success="closeModal('loginModal')"
      />
    </b-modal>
    <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>