<script>
/**
 * Widget Component
 */
import Swal from "sweetalert2";
import axios from "axios"

export default {
  data() {
    return {
      coaches: 0,
      members: 0,
      groups: 0,
      validToken: true,

      statData: [
        {
          title: "Active Members",
          icon: "ri-user-line",
          value: "0"
        },
        {
          title: "Number of Coaches",
          icon: "ri-user-2-line",
          value: "0"
        },
        {
          title: "Number of Groups",
          icon: "ri-group-line",
          value: "0"
        }
      ]
    };
  },
  watch:{
    validToken(newValue){
      console.log(newValue);
      if( newValue == false ){
        this.$parent.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted(){
    this.getDashboardCount();
  },
  methods:{
    getDashboardCount(){
      let app = this;

      Swal.fire({
        title: "Loading data",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/dashboard.php',
        {
          params: {
            request: 'getDashboardCount',
            token: this.user.token,
            id: this.user.user.id_user
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == "Invalid token"){
            this.validToken = false;
          }else{
            app.statData[0].value = response.data.coaches;
            app.statData[1].value = response.data.members;
            app.statData[2].value = response.data.groups;
          }
        }
      });
    },
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>