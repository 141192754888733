<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  //requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, error403, VuePhoneNumberInput /*NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Shop",
          href: "/"
        },
        {
          text: "Customers",
          href: "/shop/customers"
        },
        {
          text: "Customer",
          active: true
        }
      ],
      form: {
        id_customerType:              "",
        id_trade:                     "",
        nm_customerName:              "",
        nm_lastName:                  "",
        num_customerAge:              18,
        id_gender:                    "",
        dt_customerBirthday:          "",
        num_rfc:                      "",
        de_customerEmail:             "",
        num_customerPhoneNumber:      null,
        id_country:                   "",
        id_state:                     "",
        nm_city:                      "",
        de_address:                   "",
        de_phoneNumber:               "",
      },

      translations:{
        countrySelectorLabel: 'Código de País',
        countrySelectorError: 'Seleccionar ún País',
        phoneNumberLabel: 'Teléfono',
        example: 'Ejemplo'
      },
      defaultCountryCode:"MX",
      submitted:    false,
      validToken:   true,
      validAccess:  true,
      user:         {},
      trades:       [],
      countries:    [],
      states:       [],
      cities:       [],

      options:{
        format: 'YYYY-MM-DD',
      }
    };
  },
  validations: {
    form: {
      id_customerType:          { required },
      nm_customerName:          { required, minLength: minLength(2), maxLength: maxLength(200) },
      
      de_customerEmail:         { required, email },
      num_customerPhoneNumber:  { required },
      /*
      id_country:               { required },
      id_state:                 { required },
      nm_city:                  { required },
      de_address:               { required },

      id_trade: {
        required: requiredIf(function(form){
          return form.id_customerType == "0";
        })
      },
      num_rfc:{
        required: requiredIf(function(form){
          return form.id_customerType == "0";
        })
      },
      nm_lastName: {
        required: requiredIf(function(form){
          return form.id_customerType == "1";
        })
      },
      id_gender:{
        required: requiredIf(function(form){
          return form.id_customerType == "1";
        }),
      },
      dt_customerBirthday:{
        required: requiredIf(function(form){
          return form.id_customerType == "1";
        }),
      },
      num_customerAge:{
        required: requiredIf(function(form){
          return form.id_customerType == "1";
        }),
        minValue: requiredIf(function(form){
          return form.id_customerType == "1" ? minValue(18) : false;
        }),
        maxValue:requiredIf(function(form){
          return form.id_customerType == "1" ? maxValue(100) : false;
        })
      },
      */
      
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    },
    "$root.$i18n.locale": function(newValue){
      this.translations.countrySelectorLabel  = this.$root.$i18n.messages[newValue].menuitems.organizations.list.countrySelectorLabel;
      this.translations.countrySelectorError  = this.$root.$i18n.messages[newValue].menuitems.organizations.list.countrySelectorError;
      this.translations.phoneNumberLabel      = this.$root.$i18n.messages[newValue].menuitems.organizations.list.phoneNumberLabel;
      this.translations.example               = this.$root.$i18n.messages[newValue].menuitems.organizations.list.example;
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.getIndustries();
    this.getCountries();
    
    if( this.$route.params.id ){
      this.getCustomer();
    }
  },
  methods: {
    async getIndustries(){
      Swal.fire({
        title: "Loading Industries",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/trades.php',
        {
          params: {
            request: 'getTrades',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.trades = response.data;
          Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },
    getCustomer(){
      Swal.fire({
        title: "Loading customer data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/customers.php',
        {
          params: {
            request: 'getCustomerByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.customer = response.data;

            this.form.id_customerType             = app.customer.id_customerType;
            this.form.id_trade                    = app.customer.id_trade;
            this.form.nm_customerName             = app.customer.nm_customerName;
            this.form.nm_lastName                 = app.customer.nm_lastName;
            this.form.num_customerAge             = app.customer.num_customerAge;
            this.form.id_gender                   = app.customer.id_gender;
            this.form.dt_customerBirthday         = app.customer.dt_customerBirthday+" 00:00:00Z";
            
            this.form.num_rfc                     = app.customer.num_rfc;
            this.form.de_customerEmail            = app.customer.de_customerEmail;
            //this.form.num_customerPhoneNumber     = app.customer.num_customerPhoneNumber;
            
            this.form.id_country                  = app.customer.id_country;
            this.form.id_state                    = app.customer.id_state;
            this.form.nm_city                     = app.customer.nm_city;
            this.form.de_address                  = app.customer.de_address;

            if(app.customer.de_phoneNumber){
              this.form.de_phoneNumber = JSON.parse(app.customer.de_phoneNumber);
              this.defaultCountryCode = this.form.de_phoneNumber.countryCode;
              this.form.num_customerPhoneNumber = this.form.de_phoneNumber.phoneNumber;
            }else{
              this.form.num_customerPhoneNumber = app.customer.num_customerPhoneNumber;
            }

            Swal.close();

            this.getStatesByCountryID();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    getCountries(){
      Swal.fire({
        title: "Loading Countries",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/countries.php',
        {
          params: {
            request: 'getCountries',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.countries = response.data;
          Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    getStatesByCountryID(){
      let app = this;
      Swal.fire({
        title: "Loading States",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/states.php',
        {
          params: {
            request: 'getStatesByCountryID',
            idCountry: this.form.id_country,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if(response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if(response.status == 404){ app.$router.push({ name: 'Error-404' }); }
        if(response.status == 401){ app.$router.push({ name: 'Error-401' }); }

        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.states = response.data;
          Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },

    getAge(dateString) {
      console.log(dateString);
      
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      this.form.num_customerAge = age;
    },

    // eslint-disable-next-line no-unused-vars
    saveCustomer(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        this.form.id_company = this.user.user.id_company;

        if( this.$route.params.id ){
          this.form.id_customer = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving customer.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/customers.php', {
          request: 'saveCustomer',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your customer is correct "+action+"!", "success");
          app.$router.push({ name: 'customers' });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
    
    onUpdate(payload){
      this.form.de_phoneNumber = payload;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveCustomer">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="id_customerType">{{$t('menuitems.clients.list.customerType')}}</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_customerType.$error }"
                      name="id_customerType"
                      id="id_customerType"
                      v-model="form.id_customerType"
                    >
                      <option value="">{{$t('menuitems.clients.list.selectCustomerType')}}</option>
                      <option value="0">{{$t('menuitems.clients.list.company')}}</option>
                      <option value="1">{{$t('menuitems.clients.list.individual')}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_customerType.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_customerType.required">Customer type is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="nm_customerName">{{$t('menuitems.clients.list.customerName')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.nm_customerName.$error }"
                      name="nm_customerName"
                      id="nm_customerName"
                      v-model="form.nm_customerName"
                      value=""
                    >
                    <div v-if="submitted && $v.form.nm_customerName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.nm_customerName.required">Customer name is required.</span>
                      <span v-if="!$v.form.nm_customerName.minLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                      <span v-if="!$v.form.nm_customerName.maxLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="de_customerEmail">{{$t('menuitems.clients.list.customerEmail')}}</label>
                    <input
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_customerEmail.$error }"
                      name="de_customerEmail"
                      id="de_customerEmail"
                      v-model="form.de_customerEmail"
                    >
                    <div v-if="submitted && $v.form.de_customerEmail.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_customerEmail.required">Customer email is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_customerPhoneNumber">{{$t('menuitems.clients.list.phoneNumber')}}</label>
                    <VuePhoneNumberInput
                      :class="{ 'is-invalid': submitted && $v.form.num_customerPhoneNumber.$error }"
                      name="num_customerPhoneNumber"
                      id="num_customerPhoneNumber"
                      v-model="form.num_customerPhoneNumber"
                      :translations="translations"
                      :default-country-code="defaultCountryCode"
                      @update="onUpdate"
                    />
                    <div v-if="submitted && $v.form.num_customerPhoneNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_customerPhoneNumber.required">Phone number is required.</span>
                    </div>
                    <!--
                    <input
                      type="text"
                      class="form-control"
                      name="num_customerPhoneNumber"
                      id="num_customerPhoneNumber"
                      v-model="form.num_customerPhoneNumber"
                    />
                    -->
                    <!--<span class="text-muted">e.g "(xx) xxxx-xxxx"</span>-->
                  </div>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.clients.list.customerData')}}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group" v-if="form.id_customerType == 0">
                        <label for="id_trade">{{$t('menuitems.clients.list.industry')}}</label>
                        <select
                          class="form-control"
                          name="id_trade"
                          id="id_trade"
                          v-model="form.id_trade"
                        >
                          <option value="">{{$t('menuitems.clients.list.selectIndustry')}}</option>
                          <option v-for="(trade, index) in trades" :key="index" :value="trade.id_trade">{{trade.nm_trade}}</option>
                        </select>
                        <!--
                        <div v-if="submitted && $v.form.id_trade.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_trade.required">Industry is required.</span>
                        </div>
                        -->
                      </div>

                      <div class="form-group" v-if="form.id_customerType == 1">
                        <label for="nm_lastName">{{$t('menuitems.clients.list.customerLastName')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          name="nm_lastName"
                          id="nm_lastName"
                          v-model="form.nm_lastName"
                          value=""
                        >
                        <!--
                        <div v-if="submitted && $v.form.nm_lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_lastName.required">Customer last name is required.</span>
                          <span v-if="!$v.form.nm_lastName.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_lastName.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                        -->
                      </div>

                      <div class="form-group mb-3" v-if="form.id_customerType == 1">
                        <label for="dt_customerBirthday">{{$t('menuitems.clients.list.customerBirthday')}}</label>
                        <br />
                        <date-picker
                          v-model="form.dt_customerBirthday"
                          :first-day-of-week="1"
                          lang="en"
                          name="dt_customerBirthday"
                          id="dt_customerBirthday"
                          valueType="format"
                          format="YYYY-MM-DD"
                          :value="form.dt_customerBirthday"
                          @change="getAge"
                        >
                        </date-picker>
                        <!--
                        <div v-if="submitted && $v.form.dt_customerBirthday.$error" class="invalid-feedback">
                          <span v-if="!$v.form.dt_customerBirthday.required">Customer Birthdate is required.</span>
                        </div>
                        -->
                      </div>

                      <div class="form-group" v-if="form.id_customerType == 1">
                        <label for="id_gender">{{$t('menuitems.clients.list.gender')}}</label>
                        <select
                          class="form-control"
                          name="id_gender"
                          id="id_gender"
                          v-model="form.id_gender"
                        >
                          <option value="">{{$t('menuitems.clients.list.selectGender')}}</option>
                          <option value="0">{{$t('menuitems.management.list.female')}}</option>
                          <option value="1">{{$t('menuitems.management.list.male')}}</option>
                          <option value="2">{{$t('menuitems.management.list.noneBinary')}}</option>
                        </select>
                        <!--
                        <div v-if="submitted && $v.form.id_gender.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_gender.required">Gender is required.</span>
                        </div>
                        -->
                      </div>

                      <div class="form-group" v-if="form.id_customerType == 1">
                        <label for="num_customerAge">{{$t('menuitems.clients.list.customerAge')}}</label>
                        <!--
                        <NumberInputSpinner
                          :min="1"
                          :max="120"
                          :integerOnly="true"
                          v-model="form.num_customerAge"
                          :class="{ 'is-invalid': submitted && $v.form.num_customerAge.$error }"
                          name="num_customerAge"
                          id="num_customerAge"
                          value=""
                        />
                        -->
                        <input
                          type="text"
                          class="form-control"
                          name="num_customerAge"
                          id="num_customerAge"
                          v-model="form.num_customerAge"
                          value=""
                          readonly
                        />
                        <!--
                        <div v-if="submitted && $v.form.num_customerAge.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_customerAge.required">Employee age is required.</span>
                          <span v-if="!$v.form.num_customerAge.minValue">This minimum age is invalid. It should be between 18 and 100 years old.</span>
                          <span v-if="!$v.form.num_customerAge.maxValue">This maximum age is invalid. It should be between 18 and 100 years old.</span>
                        </div>
                        -->
                      </div>                      

                      
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.clients.list.invoicingData')}}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="num_rfc">{{$t('menuitems.clients.list.rfcNumber')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          name="num_rfc"
                          id="num_rfc"
                          v-model="form.num_rfc"
                          value=""
                        >
                        <!--
                        <div v-if="submitted && $v.form.num_rfc.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_rfc.required">RFC Number is required.</span>
                          <span v-if="!$v.form.num_rfc.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.num_rfc.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                        -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.clients.list.locationData')}}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_country">{{$t('menuitems.management.list.country')}}</label>
                        <select
                          class="form-control"
                          name="id_country"
                          id="id_country"
                          v-model="form.id_country"
                          @change="getStatesByCountryID"
                        >
                          <option value="">{{$t('menuitems.management.list.selectCountry')}}</option>
                          <option value="142">México</option>
                          <option value="231">United States</option>
                          <hr>
                          <option v-for="(country, index) in countries" :key="index" :value="country.id_country">{{country.nm_country}}</option>
                        </select>
                        <!--
                        <div v-if="submitted && $v.form.id_country.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_country.required">Country field is required.</span>
                        </div>
                        -->
                      </div>

                      <div class="form-group">
                        <label for="id_state">{{$t('menuitems.management.list.state')}}</label>
                        <select
                          class="form-control"
                          name="id_state"
                          id="id_state"
                          v-model="form.id_state"
                          value=""
                        >
                          <option value="">{{$t('menuitems.management.list.selectState')}}</option>
                          <option v-for="(state, index) in states" :key="index" :value="state.id_state">{{state.nm_state}}</option>
                        </select>
                        <!--
                        <div v-if="submitted && $v.form.id_state.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_state.required">State/Province field is required.</span>
                        </div>
                        -->
                      </div>

                      <div class="form-group">
                        <label for="nm_city">{{$t('menuitems.clients.list.city')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          name="nm_city"
                          id="nm_city"
                          v-model="form.nm_city"
                          value=""
                        >
                        <!--
                        <div v-if="submitted && $v.form.nm_city.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_city.required">City is required.</span>
                          <span v-if="!$v.form.nm_city.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_city.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                        -->
                      </div>

                      <div class="form-group">
                        <label for="de_address">{{$t('menuitems.management.list.address')}}</label>
                        <textarea
                          class="form-control"
                          name="de_address"
                          id="de_address"
                          v-model="form.de_address"
                          rows="3"
                          value=""
                        >
                        </textarea>
                        <!--
                        <div v-if="submitted && $v.form.de_address.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_address.required">Address field is required.</span>
                          <span v-if="!$v.form.de_address.minLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                          <span v-if="!$v.form.de_address.maxLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                        </div>
                        -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.clients.list.save')}}</button>
                    <router-link to="/shop/customers" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.clients.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  hr{
    padding-top:5px;
    padding-bottom: 5px;
  }
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>