<script type="text/javascript" src="cordova.js"></script>
<script>
import vue2Dropzone from "vue2-dropzone";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import {saveAs} from 'file-saver';
import error403 from '../utility/error-403';
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Purchase Order registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { vueDropzone: vue2Dropzone, Layout, PageHeader, error403, VueBottomSheetVue2/*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "Purchase Order registration",
      items: [
        {
          text: "Shop",
          href: "/"
        },
        {
          text: "Purchase Orders",
          href: "/Shop/purchaseorders"
        },
        {
          text: "Purchase Order",
          active: true
        }
      ],
      form: {
        id_workorder:     "",
        num_quantity:     "",
        num_total:        "",
        de_material:      "",
        de_description:   "",
        de_notes:         "",
        url_invoice:      "",
        de_status:        "NEW",
      },

      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/purchaseorders.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },

      keyComponent: 0,
      status:       ["NEW", "IN PROGRESS", "ON HOLD", "COMPLETED"],
      submitted:    false,
      validToken:   false,
      validAccess:  true,
      showDropZone: true,
      platform: '',
      cameraPicture: "",
    };
  },
  validations: {
    form: {
      id_workorder:     { required },
      num_quantity:     { required },
      de_material:      { required },
      de_description:   { required },
      de_notes:         { required },
      de_status:        { required },

      num_total:        { required: requiredIf(function(form){ return form.de_status != "NEW"}) },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    document.addEventListener("deviceready", this.onDeviceReady, false);

    this.getSites();
    
    if( this.$route.params.id ){
      this.getPurchaseOrderByID();
    }

    if( this.user.user.id_role != "1" && this.user.user.id_role != "2" && this.user.user.id_role != "12"){
      this.status = ["NEW", "IN PROGRESS", "COMPLETED"];
    }
  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform     = platform;
        this.showDropZone = false;
      }
    },

    openActionSheet() {
        this.$refs.myBottomSheet.open();
      },
    closeActionSheet() {
      this.$refs.myBottomSheet.close();
    },

    getSites(){
      Swal.fire({
        title: "Loading Sites",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSites',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.sites = response.data;
          Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getPurchaseOrderByID(){
      Swal.fire({
        title: "Loading purchase order data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/purchaseorders.php',
        {
          params: {
            request: 'getPurchaseOrderByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.labor = response.data;

            app.form.id_workorder     = response.data.id_workorder;
            app.form.num_quantity     = response.data.num_quantity;
            app.form.num_total        = response.data.num_total;
            app.form.de_material      = response.data.de_material;
            app.form.de_description   = response.data.de_description;
            app.form.de_notes         = response.data.de_notes;
            app.form.de_status        = response.data.de_status;
            app.form.url_invoice      = response.data.url_invoice;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    saveCustomer(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_purchaseOrder = this.$route.params.id;
        }

        if(
            (this.form.de_status == "COMPLETED"  &&  this.form.url_invoice == '') &&
            (this.form.de_status == "COMPLETED"  &&  this.$refs.myVueDropzone.dropzone.files.length <= 0)
          ){
          Swal.fire("Ooops", "You cannot close this purchase order without a hard copy/ticket of the invice. Please attach the copy of the invoice into this purcharse order by uploading from the upload box.", "error");
          return;
        }

        Swal.fire({
          title: "Saving purchase order.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if( !this.platform ){
          if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
            this.$refs.myVueDropzone.processQueue();
          }else{
            this.sendToSave();
          }
        }else{
          this.sendToSave();
        }
      }
    },

    sendToSave(){
      axios.post(window.serverPath+'dist/mvw/controllers/purchaseorders.php', {
        request: 'savePurchaseOrder',
        data: this.form,
        token: this.user.token,
      })
      .then(function (response) {
        if( response.data == 'Invalid token' ){
          this.validToken = false;
        }else{
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your purchase order is correct "+action+"!", "success");
          app.$router.push({ name: 'purchaseorders' });
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
      });
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'savePurchaseOrder');
      formData.append('token',     this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },
    uploadCompleted(response){
      let app = this;

      if( isNaN(response.xhr.response) ){
        Swal.fire("Ooops!", response.xhr.response, "error")
      }else{
        Swal.fire("Good job!", "Payout has been successfully saved!", "success")
        .then(function(confirmation){
          if(confirmation.isConfirmed){
           app.$router.push({ name: 'purchaseorders' });
          }
        });
      }
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_invoice);
    },

    downloadTicket(){
      if(this.platform != "" ){
        navigator.app.loadUrl(window.serverPath+'dist'+this.form.url_invoice, { openExternal: true });
      }else{
        window.open(window.serverPath+'dist'+this.form.url_invoice, "_blank");
      }
    },

    /**
     * Warning: Using DATA_URL is not recommended! The DATA_URL destination
     * type is very memory intensive, even with a low quality setting. Using it
     * can result in out of memory errors and application crashes. Use FILE_URI
     * instead.
     */
    openCamera(){
      this.closeActionSheet();
      if(navigator.camera){
        navigator.camera.getPicture(this.onSuccess, this.onFail, { quality: 25,
          destinationType: Camera.DestinationType.DATA_URL
        });  
      }else{
        alert('Imposible open camera');
      }
    },

    onSuccess(imageData) {
        var image = document.getElementById('myImage');
        image.src = imageData;

        this.form.url_invoice = imageData;
    },

    onFail(message) {
        alert('Failed because: ' + message);
    },

    clickFile(){
      if(this.platform){
        this.openActionSheet();
      }else{
        this.openFileExplorer();
      }
    },

    openFileExplorer(){
      document.getElementsByClassName("dropzone")[0].click();
      this.showDropZone = true;
      this.closeActionSheet();
    },

    resetCameraFile(){
      if(this.platform){
        var image = document.getElementById('myImage');
        image.src = "";
        this.form.url_invoice = "";
      }
    },
    hideDropZone(){
      if(this.platform){
        this.showDropZone = false;
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveCustomer">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="id_workorder">{{$t('menuitems.pors.list.workorder')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_workorder.$error }"
                      name="id_workorder"
                      id="id_workorder"
                      v-model="form.id_workorder"
                      value=""
                    >
                    <div v-if="submitted && $v.form.id_workorder.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_workorder.required">Labor name is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_quantity">{{$t('menuitems.pors.list.quantity')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_quantity.$error }"
                      name="num_quantity"
                      id="num_quantity"
                      v-model="form.num_quantity"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_quantity.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_quantity.required">Quantity of material.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_total">{{$t('menuitems.pors.list.totalCost')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_total.$error }"
                      name="num_total"
                      id="num_total"
                      v-model="form.num_total"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_total.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_total.required">Material price.</span>
                    </div>
                  </div>

                  <div v-if="platform" class="form-group mb-4">
                    <label for="dropzone">{{$t('menuitems.pors.list.uploadTicket')}}</label>
                    <div class="row">
                      <div class="ml-3 mr-2">
                        <button type="button" class="btn btn-sm btn-secondary" @click="clickFile">
                          <i class="fas fa-upload"></i>
                          SELECT TICKET
                        </button>
                      </div>
                      <div class="ml-2">
                        <img :src="cameraPicture" ref="myImage" id="myImage" width="100px" />
                      </div>
                      <vue-bottom-sheet-vue2
                        ref="myBottomSheet"
                        :max-height="600"
                      >
                        <div class="row">
                          <div class="col-sm-12 ml-0 p-4">
                            <div class="form-group">
                              <button type="button" class="btn btn-block btn-outline-dark" @click="openCamera"><i class="fas fa-camera"></i> Open Camera</button>
                              <button type="button" class="btn btn-block btn-outline-dark" @click="openFileExplorer"><i class="far fa-folder-open"></i> Open File Explorer</button>
                            </div>
                          </div>
                        </div>
                      </vue-bottom-sheet-vue2>
                    </div>
                    <!--
                    <div class="row">
                      <button v-if="form.url_invoice" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      {{$t('menuitems.pors.list.downloadTicket')}}
                        <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                      </button>
                      <button v-if="form.url_invoice" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" @click="openTicket">
                        {{$t('menuitems.pors.list.viewTicket')}}
                        <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                      </button>
                    </div>
                    -->
                  </div>

                  <div v-show="showDropZone || $refs.myVueDropzone.dropzone.files.length > 0" class="form-group">
                    <label v-if="!platform" for="dropzone">{{$t('menuitems.pors.list.uploadTicket')}}</label>
                    <vue-dropzone
                      :key="keyComponent"
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                      @vdropzone-file-added="resetCameraFile"
                      @vdropzone-removed-file="hideDropZone"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>{{$t('menuitems.pors.list.uploadAd')}}</h4>
                      </div>
                    </vue-dropzone>
                    
                    <button v-if="form.url_invoice" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      {{$t('menuitems.pors.list.downloadTicket')}}
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_invoice" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" @click="openTicket">
                      {{$t('menuitems.pors.list.viewTicket')}}
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="de_material">{{$t('menuitems.pors.list.material')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_material.$error }"
                      name="de_material"
                      id="de_material"
                      v-model="form.de_material"
                      value=""
                    >
                    <div v-if="submitted && $v.form.de_material.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_material.required">Please enter the material needed, this is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="de_description">{{$t('menuitems.pors.list.materialDescription')}}</label>
                    <textarea
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_description.$error }"
                      name="de_description"
                      id="de_description"
                      v-model="form.de_description"
                      value=""
                      rows="5"
                    ></textarea>
                    <div v-if="submitted && $v.form.de_description.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_description.required">Please enter the material description.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="de_notes">{{$t('menuitems.pors.list.notes')}}</label>
                    <textarea
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_notes.$error }"
                      name="de_notes"
                      id="de_notes"
                      v-model="form.de_notes"
                      value=""
                      rows="5"
                    ></textarea>
                    <div v-if="submitted && $v.form.de_notes.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_notes.required">Please enter a note or justification for this purchase order.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="de_status">{{$t('menuitems.pors.list.status')}}</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_status.$error }"
                      name="de_status"
                      id="de_status"
                      v-model="form.de_status"
                    >
                      <option v-for="(state, index) in status" :key="index" :value="state">{{state}}</option>
                    </select>
                    <div v-if="submitted && $v.form.de_status.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_status.required">Status required.</span>
                    </div>
                  </div>

                  <div v-if="form.de_status == 'ON HOLD'" class="form-group">
                    <label for="de_onHoldJustification">{{$t('menuitems.pors.list.holdJustification')}}</label>
                    <textarea name="textarea" v-model="form.de_onHoldJustification" rows="5" class="form-control"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.pors.list.save')}}</button>
                    <router-link to="/shop/labors" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.pors.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .dropzone{ padding:0px !important; }
</style>