<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  minValue,
  maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, error403 /*NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Company",
          href: "#/"
        },
        {
          text: "Employees",
          href: "#/company/employees"
        },
        {
          text: "Employee",
          active: true
        }
      ],
      form: {
        id_company:                   "",
        id_site:                      "",
        id_department:                "",
        id_levelJobPosition:          "",
        id_jobTitle:                  "",
        id_manager:                   "",
        id_gender:                    "",
        nm_employee:                  "",
        nm_lastName:                  "",
        num_employeeAge:              0,
        num_employeeCompanyPhone:     null,
        num_employeeCompanyExtension: null,
        de_employeeCompanyEmail:      "",
        dt_employeeHireDate:          ""
      },
      submitted: false,
      user: {},
      companies: [],
      sites: [],
      departments: [],
      jobtitles: [],
      superiors: [],
      options:{
        format: 'YYYY-MM-DD',
      },
      validToken: true,
      validAccess: true,
    };
  },
  validations: {
    form: {
      id_company:                   { required },
      id_site:                      { required },
      id_department:                { required },
      id_levelJobPosition:          { required },
      id_jobTitle:                  { required },
      id_gender:                    { required },
      nm_employee:                  {required, minLength: minLength(2)},
      nm_lastName:                  {required, minLength: minLength(2), maxLength: maxLength(50)},
      num_employeeAge:              { required, minValue:minValue(18), maxValue:maxValue(100) },
      de_employeeCompanyEmail:      { required, email },
      dt_employeeHireDate:          { required }
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      this.getEmployee();
    }else{
      this.getCompanies();
    }
  },
  methods: {
    async getCompanies(){
      Swal.fire({
        title: "Loading Companies",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
         window.serverPath+'dist/mvw/controllers/organizations.php',
        {
          params: {
            request: 'getOrganizationsForCombos',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.companies = response.data;
            Swal.close();  
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },
    async getSites(){
      Swal.fire({
        title: "Loading sites.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app                         = this;

      axios
      .get(
         window.serverPath+'dist/mvw/controllers/sites.php',
        {
          params: {
            request:  'getSitesForCombos',
            id:       this.form.id_company,
            type:     'company',
            token:    this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.sites = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    async getDepartments(){
      Swal.fire({
        title: "Loading departments.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app                         = this;

      axios
      .get(
         window.serverPath+'dist/mvw/controllers/departments.php',
        {
          params: {
            request:  'getDepartmentsBySiteID',
            idSite:   this.form.id_site,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.departments = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    async getJobTitles(){
      Swal.fire({
        title: "Loading job titles.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/jobpositions.php',
        {
          params: {
            request:  'getJobPositionsForCombos',
            id:       this.form.id_department,
            type:     'department',
            level:    this.form.id_levelJobPosition,
            token:    this.user.token
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.jobtitles = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    async getSuperiors(){
      Swal.fire({
        title: "Loading superiors",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/employees.php',
        {
          params: {
            request:  'getEmployeesForCombos',
            id:       this.form.id_company,
            type:     'company',
            level:    this.form.id_levelJobPosition,
            op:       'lt',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.superiors = response.data;
            Swal.close();  
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    getEmployee(){
      Swal.fire({
        title: "Loading employee data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/employees.php',
        {
          params: {
            request: 'getEmployeesByID',
            id:this.$route.params.id,
            token: this.user.token
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.employee = response.data;
            this.form.id_company                    = app.employee.id_company;
            this.form.id_site                       = app.employee.id_site;
            this.form.id_department                 = app.employee.id_department;
            this.form.id_levelJobPosition           = app.employee.id_levelJobPosition;
            this.form.id_jobTitle                   = app.employee.id_jobTitle;
            this.form.id_manager                    = app.employee.id_manager;
            this.form.id_gender                     = app.employee.id_gender;
            this.form.nm_employee                   = app.employee.nm_employee;
            this.form.nm_lastName                   = app.employee.nm_lastName;
            this.form.num_employeeAge               = app.employee.num_employeeAge;
            this.form.num_employeeCompanyPhone      = app.employee.num_employeeCompanyPhone;
            this.form.num_employeeCompanyExtension  = app.employee.num_employeeCompanyExtension;
            this.form.de_employeeCompanyEmail       = app.employee.de_employeeCompanyEmail;
            this.form.dt_employeeHireDate           = app.employee.dt_employeeHireDate+" 00:00:00Z";
            Swal.close();
            this.getCompanies();
            this.getSites();
            this.getDepartments();
            this.getJobTitles();
            this.getSuperiors();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveEmployee(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_employee = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving employee.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post( window.serverPath+'dist/mvw/controllers/employees.php', {
          request: 'saveEmployee',
          data: this.form,
          token: this.user.token
        })
        .then(function (response) {
          if(response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }
            Swal.fire("Good job!", "Your employee is correct "+action+"!", "success");
            app.$router.push({ name: 'employees' });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }else{
        console.log(this.$v.form);
      }
    },
    resetFromCompany(){
      this.sites                    = [];
      this.departments              = [];
      this.jobtitles                = [];
      this.form.id_site             = "";
      this.form.id_department       = "";
      this.form.id_levelJobPosition = "";
      this.form.id_jobTitle         = "";
    },
    resetFromSite(){
      this.departments              = [];
      this.jobtitles                = [];
      this.form.id_department       = "";
      this.form.id_levelJobPosition = "";
      this.form.id_jobTitle         = "";
    },
    resetFromDepartment(){
      this.jobtitles                = [];
      this.form.id_levelJobPosition = "";
      this.form.id_jobTitle         = "";
    },
    resetFromLevelJobPosition(){
      this.jobtitles                = [];
      this.form.id_jobTitle         = "";
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveEmployee">
              <div class="row">
                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.employees.list.personalData')}}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_employee">{{$t('menuitems.employees.list.employeeName')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_employee.$error }"
                          name="nm_employee"
                          id="nm_employee"
                          v-model="form.nm_employee"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_employee.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_employee.required">Employee name is required.</span>
                          <span v-if="!$v.form.nm_employee.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_employee.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="nm_lastName">{{$t('menuitems.employees.list.employeeLastName')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_lastName.$error }"
                          name="nm_lastName"
                          id="nm_lastName"
                          v-model="form.nm_lastName"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_lastName.required">Employee last name is required.</span>
                          <span v-if="!$v.form.nm_lastName.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_lastName.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="num_employeeAge">{{$t('menuitems.employees.list.employeeAge')}}</label>
                        <!--
                        <NumberInputSpinner
                          :min="1"
                          :max="120"
                          :integerOnly="true"
                          v-model="form.num_employeeAge"
                          :class="{ 'is-invalid': submitted && $v.form.num_employeeAge.$error }"
                          name="num_employeeAge"
                          id="num_employeeAge"
                          value=""
                        />
                        -->
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_employeeAge.$error }"
                          name="num_employeeAge"
                          id="num_employeeAge"
                          v-model="form.num_employeeAge"
                          value=""
                        />
                        <div v-if="submitted && $v.form.num_employeeAge.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_employeeAge.required">Employee age is required.</span>
                          <span v-if="!$v.form.num_employeeAge.minValue">This minimum age is invalid. It should be between 18 and 100 years old.</span>
                          <span v-if="!$v.form.num_employeeAge.maxValue">This maximum age is invalid. It should be between 18 and 100 years old.</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="id_gender">{{$t('menuitems.employees.list.gender')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_gender.$error }"
                          name="id_gender"
                          id="id_gender"
                          v-model="form.id_gender"
                        >
                          <option value="">{{$t('menuitems.employees.list.selectGender')}}</option>
                          <option value="0">{{$t('menuitems.management.list.female')}}</option>
                          <option value="1">{{$t('menuitems.management.list.male')}}</option>
                          <option value="2">{{$t('menuitems.management.list.noneBinary')}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_gender.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_gender.required">Gender is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.employees.list.organizationalData')}}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_company">{{$t('menuitems.employees.list.company')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_company.$error }"
                          name="id_company"
                          id="id_company"
                          v-model="form.id_company"
                          @change="resetFromCompany();getSites()"
                        >
                          <option value="">{{$t('menuitems.employees.list.selectCompany')}}</option>
                          <option v-for="(company, index) in companies" :key="index" :value="company.id_company">{{company.nm_company}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_company.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_company.required">Company field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_site">{{$t('menuitems.employees.list.site')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_site.$error }"
                          name="id_site"
                          id="id_site"
                          v-model="form.id_site"
                          @change="resetFromSite();getDepartments()"
                        >
                          <option value="">{{$t('menuitems.employees.list.selectSite')}}</option>
                          <option v-for="(site, index) in sites" :key="index" :value="site.id_site">{{site.nm_site}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_site.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_site.required">Site is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_department">{{$t('menuitems.employees.list.department')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_department.$error }"
                          name="id_department"
                          id="id_department"
                          v-model="form.id_department"
                          @change="resetFromDepartment"
                        >
                          <option value="">{{$t('menuitems.employees.list.selectDepartment')}}</option>
                          <option v-for="(department, index) in departments" :key="index" :value="department.id_department">{{department.nm_department}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_department.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_department.required">Department is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_levelJobPosition">{{$t('menuitems.employees.list.positionLevel')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_levelJobPosition.$error }"
                          name="id_levelJobPosition"
                          id="id_levelJobPosition"
                          v-model="form.id_levelJobPosition"
                           @change="resetFromLevelJobPosition();getJobTitles();getSuperiors()"
                        >
                          <option value="">{{$t('menuitems.employees.list.selectPositionLevel')}}</option>
                          <option value="1">{{$t('menuitems.jobpositions.list.topManagement')}}</option>
                          <option value="2">{{$t('menuitems.jobpositions.list.siteDirector')}}</option>
                          <option value="3">{{$t('menuitems.jobpositions.list.manager')}}</option>
                          <option value="4">{{$t('menuitems.jobpositions.list.supervisor')}}</option>
                          <option value="5">{{$t('menuitems.jobpositions.list.employee')}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_levelJobPosition.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_levelJobPosition.required">Level Position is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_jobTitle">{{$t('menuitems.employees.list.jobTitle')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_jobTitle.$error }"
                          name="id_jobTitle"
                          id="id_jobTitle"
                          v-model="form.id_jobTitle"
                        >
                          <option value="">{{$t('menuitems.employees.list.selectJobTitle')}}</option>
                          <option v-for="(jobtitle, index) in jobtitles" :key="index" :value="jobtitle.id_jobposition">{{jobtitle.nm_jobTitle}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_jobTitle.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_jobTitle.required">Job Title is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_manager">{{$t('menuitems.employees.list.reportsTo')}}</label>
                        <select
                          class="form-control"
                          name="id_manager"
                          id="id_manager"
                          v-model="form.id_manager"
                        >
                          <option value="">{{$t('menuitems.employees.list.selectReportsTo')}}</option>
                          <option v-for="(superior, index) in superiors" :key="index" :value="superior.id_employee">{{superior.nm_employee + " " +superior.nm_lastName}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.employees.list.locationData')}}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_jobTitle">{{$t('menuitems.employees.list.phoneCompany')}}</label>
                        <input
                          v-mask="'+(##) ###-###-####'"
                          type="text"
                          class="form-control"
                          name="num_employeeCompanyPhone"
                          id="num_employeeCompanyPhone"
                          v-model="form.num_employeeCompanyPhone"
                        />
                        <!--<span class="text-muted">e.g "(xx) xxxx-xxxx"</span>-->
                      </div>

                      <div class="form-group">
                        <label for="num_employeeCompanyExtension">{{$t('menuitems.employees.list.phoneExtension')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          name="num_employeeCompanyExtension"
                          id="num_employeeCompanyExtension"
                          v-model="form.num_employeeCompanyExtension"
                          value=""
                        >
                      </div>

                      <div class="form-group">
                        <label for="de_employeeCompanyEmail">{{$t('menuitems.employees.list.employeeEmail')}}</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_employeeCompanyEmail.$error }"
                          name="de_employeeCompanyEmail"
                          id="de_employeeCompanyEmail"
                          v-model="form.de_employeeCompanyEmail"
                        >
                        <div v-if="submitted && $v.form.de_employeeCompanyEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_employeeCompanyEmail.required">Employee email is required.</span>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label for="dt_employeeHireDate">{{$t('menuitems.employees.list.hiresDate')}}</label>
                        <br />
                        <date-picker
                          v-model="form.dt_employeeHireDate"
                          :first-day-of-week="1"
                          lang="en"
                          :class="{ 'is-invalid': submitted && $v.form.dt_employeeHireDate.$error }"
                          name="dt_employeeHireDate"
                          id="dt_employeeHireDate"
                          valueType="format"
                          format="YYYY-MM-DD"
                          :value="form.dt_employeeHireDate"
                        >
                        </date-picker>
                        <div v-if="submitted && $v.form.dt_employeeHireDate.$error" class="invalid-feedback">
                          <span v-if="!$v.form.dt_employeeHireDate.required">Employee hires date is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.employees.list.save')}}</button>
                    <router-link to="/company/employees" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.employees.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>