<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from 'axios';

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Attendance registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect, DatePicker},
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title:"",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Payments",
          href: "#/payments"
        },
        {
          text: "Payments",
          active: true
        }
      ],

      load:                 0,
      pools:                [],
      coaches:              [],
      groups:               [],
      members:              [],
      payments:             [],
      attendances:          [],
      selectedPool:         "",
      selectedCoach:        "",
      selectedGroup:        "",
      selectedMember:       "",
      selectedPaymentType:  "",
      url_file:             "",

      server:               "",

      form: {
      id_member:          "",
      num_totalPayment:   "",
      dt_paymentDate:     "",
      id_paymentType:     "",
      },
      submitted: false,
      validToken: false,
      options:{
        format: 'YYYY-MM-DD',
      }
    };
  },
  validations: {
    form: {
      id_member:          { required },
      num_totalPayment:   { required },
      dt_paymentDate:     { required },
      id_paymentType:     { required },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath+'dist';
    this.getMembers();
  },
  methods: {
    async getMembers(){
      let app = this;

      Swal.fire({
        title: "Loading members",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/members.php',
        {
          params: {
            request:  'getMembers',
            id:       this.user.user.id,
            token:    this.user.token,
          }
        }
      )
      .then(response => {
        Swal.close();
        if( response.status == 200 ){
          if( response.data == "Invalid token"){
            Swal.close();
            app.validToken = false;
          }else{
            app.members = response.data;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    async getPaymentsByMemberID(option, id){
      let app = this;
      id;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/payments.php',
        {
          params: {
            request:  'getPaymentsByMemberID',
            id:       option.id_member,
            token:    this.user.token,
          }
        }
      )
      .then(response => {
        Swal.close();
        if( response.status == 200 ){
          if( response.data == "Invalid token"){
            Swal.close();
            app.validToken = false;
          }else{
            if(Array.isArray(response.data)){
              app.payments = response.data;
            }
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    checkAssistance(member){
      console.log(member);
      this.form.id_pool       = member.id_pool;
      this.form.id_coach      = member.id_coach;
      this.form.id_group      = member.id_group;
      this.form.id_member     = member.id_member;
      this.form.id_schedule   = this.selectedGroup.id_schedule;

      member.checked = true;

      // stop here if form is invalid
      this.$v.$touch();

      if( !this.$v.form.$error ){
        Swal.fire({
          title: "Saving attendance.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/attendances.php', {
          request: 'saveAttendance',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.status == 200 ){
            if( response.data.error ){
              Swal.fire("Ooops!" + response.data.error, "Seems like some error ocurr, please try again.", "error");
            }else{
              Swal.fire("Good job!", "Attendance for "+ member.name +" is saved!", "success");
            }
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }else{
        console.log(this.$v);
      }
    },

    rowClicked(item) {
      let app = this;

      if(!item._rowVariant){
        if(item.selected){
          let index = 0;
          console.log('selected');

          this.$set(item, 'selected', false);
          delete item._rowVariant;
          
          this.selectedMembers.forEach(function(member){
            if(member.id_member == item.id_member){
              app.selectedMembers.splice(index, 1);
            }
            index ++;
          });

        }else{
          this.$set(item, 'selected', true);
          this.selectedMembers.push(item);
        }
      }
    },

    tbodyRowClass(item) {
      /* Style the row as needed */
      if(item.selected){
        //return ["b-table-row-selected", "table-primary", "cursor-pointer"]
        return ["b-table-row-selected", "table-active"];
      }
    },

    savePayment(e) {
      let app = this;

      e.preventDefault();

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      this.form.id_member         = this.selectedMember.id_member;
      this.form.id_userAccount    = this.selectedMember.id_userAccount;
      this.form.id_paymentOrigin  = 0;
      this.form.id_registar       = this.user.user.id_user;

      
      if( !this.$v.form.$error ){
        Swal.fire({
          title: "Saving payment.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/payments.php', {
          request: 'savePayment',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Payment is correct "+action+"!", "success");
          app.$router.push({ name: 'payments' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="savePayment">
              <div class="row">
                <div class="col-sm-7">
                  <h4 class="card-title">{{ $t('menuitems.attendances.list.personalData') }}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_member">{{ $t('menuitems.members.list.name') }}</label>
                        <multiselect
                          id="id_member"
                          v-model="selectedMember"
                          :options="members"
                          :multiple="false"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="SELECT MEMBER"
                          label="name"
                          track-by="name"
                          :preselect-first="false"
                          @select="getPaymentsByMemberID"
                        ></multiselect>
                      </div>

                      <div class="form-group">
                        <label for="num_totalPayment">{{ $t('menuitems.payments.list.quantity') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_totalPayment.$error }"
                          name="num_totalPayment"
                          id="num_totalPayment"
                          v-model="form.num_totalPayment"
                          value=""
                        >
                        <div v-if="submitted && $v.form.num_totalPayment.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_totalPayment.required">Payment is required.</span>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label for="dt_paymentDate">{{ $t('menuitems.payments.list.paymentDate') }}</label>
                        <br />
                        <date-picker
                          v-model="form.dt_paymentDate"
                          :first-day-of-week="1"
                          lang="en"
                          :class="{ 'is-invalid': submitted && $v.form.dt_paymentDate.$error }"
                          name="dt_paymentDate"
                          id="dt_paymentDate"
                          valueType="format"
                          format="YYYY-MM-DD"
                          :value="form.dt_paymentDate"
                        >
                        </date-picker>
                        <div v-if="submitted && $v.form.dt_paymentDate.$error" class="invalid-feedback">
                          <span v-if="!$v.form.dt_paymentDate.required">Payment date is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_paymentType">{{ $t('menuitems.payments.list.paymentType') }}</label>
                        <select
                          class="form-control"
                          name="id_paymentType"
                          id="id_paymentType"
                          v-model="form.id_paymentType"
                          :class="{ 'is-invalid': $v.form.id_paymentType.$error }"
                        >
                          <option value="">{{ $t('menuitems.payments.list.selectPaymentType') }}</option>
                          <option value="1">{{ $t('menuitems.payments.list.cash') }}</option>
                          <option value="2">{{ $t('menuitems.payments.list.transfer') }}</option>
                          <option value="3">{{ $t('menuitems.payments.list.check') }}</option>
                          <option value="4">{{ $t('menuitems.payments.list.credit') }}</option>
                          <option value="5">{{ $t('menuitems.payments.list.debit') }}</option>
                        </select>
                        <div v-if="$v.form.id_paymentType.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_paymentType.required">Payment type is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-5">
                  <h4>Payment log of: (member_name)<br></h4>
                  <h5><i>&nbsp;</i></h5>
                  <b-table
                    class="mt-3"
                    sticky-header
                    small
                    :items="payments"
                    :fields="['date', 'quantity']"
                    responsive="sm"
                    style="max-height: 450px !important;"
                  >
                  </b-table>
                </div>
              </div>

              
              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{ $t('menuitems.payments.list.save') }}</button>
                    <router-link to="/payments" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{ $t('menuitems.payments.list.cancel') }} </button>
                    </router-link>
                  </div>
                </div>
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-input-tag-wrapper {
    height: auto !important;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #5664d2 !important;
    border: 1px solid #5664d2 !important;
    color: #fff !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff !important;
  }
</style>