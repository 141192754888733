<script type="text/javascript" src="cordova.js"></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

//import { tableData } from "./dataAdvancedtable";
import Swal from "sweetalert2";
import axios from 'axios';

import "export-json-excel";
import {saveAs} from 'file-saver';

import VueJsonToCsv from 'vue-json-to-csv'

import error403 from '../utility/error-403'

/**
 * Advanced table component
 */
export default {
  name: "log",
  page: {
    title: "Log",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403 },
  props: {
    menu:         String,
    entity:       String,
    addLink:      String,
    actions:      Object,
    rights:       Array,
    suscription:  Array
  },
  data() {
    return {
      componentKey: 0,
      tableData: [],
      title: "",
      tableName: "",
      addAction: "",
      items: [
        {
          text: "Tables",
          href: "/"
        },
        {
          text: "Advanced",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      fields: [],
      fixedEntity: "",
      validToken: true,
      validAccess: true,
      platform: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  //As the component us re-used for various modules, this WATCHER allows to reload the data.
  watch:{
    "entity": function(oldvalue, newvalue){
      if(oldvalue !== newvalue){
        this.setInit();
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    },
    "suscription": function(newvalue, oldvalue){
      if(!newvalue.includes(this.user.user.id_suscriptionType)){
        this.validAccess = false;
      }else{
        this.validAccess = true;
      }
    }
    
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.checkAccess();
    document.addEventListener("deviceready", this.onDeviceReady, false);

    this.componentKey += 1;
    // Set the initial number of items
    /*
    this.totalRows  = this.items.length;
    if(this.rights.includes(this.user.user.id_role)){
      this.setInit();
    }else{
      this.validAccess = false;
    }
    */
  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform     = platform;
      }
    },

    downloadJSON(){
      // Convert JSON data to CSV
      let csvData = this.jsonToCsv(this.tableData); // Add .items.data
      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: 'text/csv' });
      let url = window.URL.createObjectURL(blob);

      //const pathToFile = cordova.file.dataDirectory;
      const fileName = this.entity+'_'+(Date.now()).toString()+'.csv';

      if(this.platform != null){
        Swal.fire({
          title: "Generating file...",
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        window.resolveLocalFileSystemURL(
            cordova.file.externalCacheDirectory,
            function(directoryEntry) {
                directoryEntry.getFile(
                    fileName,
                    { create: true },
                    function(fileEntry) {
                        fileEntry.createWriter(
                            function(fileWriter) {
                                Swal.close();
                                fileWriter.onwriteend = (eEvent) => {
                                    Swal.fire("GREAT !", "File has been downloaded", "success");
                                    //alert('onwriteend: ' + 'File stored at: '+cordova.file.externalCacheDirectory);
                                    resolve(fileWriter.localURL);
                                };

                                fileWriter.onerror = (eError) => {
                                    Swal.fire("Ooops", "There was an error trying to download the report.", "error");
                                    //alert('onErrorCreateWriter: ' + JSON.stringify(eError));
                                    reject(eError);
                                };

                                fileWriter.write(blob);
                                cordova.plugins.fileOpener2.open(
                                  cordova.file.externalCacheDirectory+fileName,
                                  'text/csv',
                                  {
                                    error: function(e){
                                      Swal.fire("Ooops", "Seems like is not possible to open the file. "+e.message, "error");
                                    },
                                    success: function(){ console.log('success'); }
                                  }
                                );
                            },
                            (eError) => {
                                alert('onErrorCreateFile: ' + eError);
                                reject(eError);
                            }
                        );
                    }
                );
            }
        );
      }else{
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    },

    jsonToCsv(jsonData) {
      let csv = '';
      // Get the headers
      let headers = Object.keys(jsonData[0]);
      csv += headers.join(',') + '\n';
      // Add the data
      jsonData.forEach(function (row) {
          let data = headers.map(header => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
          csv += data + '\n';
      });
      return csv;
    },

    checkAccess(){
      if( this.user.user.id_role == 12 ){
        this.validAccess = true;
        this.setInit();
      }else{
        if( this.suscription.indexOf( this.user.user.id_suscriptionType ) !== -1 ){
          if( this.rights.indexOf( this.user.user.id_role ) !== -1 ){
            this.validAccess = true;
            this.setInit();
          }else{
            this.validAccess = false;
          }
        }else{
          this.validAccess = false;
        }
      }
    },

    downloadInvoice(invoicePath){
      if(this.platform){
        window.open(invoicePath);
      }else{
        //let app = this;
        axios
        .get(invoicePath, {responseType: 'blob'})
        .then(response => {
            //console.log(algo);
            saveAs(response.data);
        });
      }

        
    },

    sendInvoice(idWorkorder){
      Swal.fire({
        title: "Sending invoice.",
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/workorders.php',
        {
          params: {
            request: 'sendPDFByWorkorderID',
            id: idWorkorder,
            token: this.user.token
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(response.data == "OK"){
            Swal.fire("GREATE!", "Invoice has been sent to the customer email.", "success");
          }else{
            Swal.fire("Ooops!", "There was an error trying to send the invoice to the customer email. Please try again.", "error");
          }
        }else{
          Swal.fire("Ooops!", "There was an error trying to send the invoice to the customer email. Please try again.", "success");
        }
      }).catch(e =>{
        Swal.fire("Ooops!", "There was an error trying to send the invoice to the customer email. Please try again. Error Code: "+e, "success");
      });
    },

    checkOtherEntities(entity){
      var entities = {
        "payout":   "payment",
        "customer": "client",
        "profile":  "car file"
      }

      if(entities[entity]){
        return entities[entity];
      }else{
        return entity;
      }
    },

    getFields(){
      let app = this;
      let ent = this.checkOtherEntities(this.entity) + "s";
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/'+ent.replace(" ","").replace("-","")+'.php',
        {
          params: {
            request: 'getFields',
            token: this.user.token
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.fields = response.data;
            app.sortBy = response.data[0].key;

            response.data.forEach(function(item){
              app.filterOn.push(item.field);
            });
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load data! Please load this module again.", "error");
        }
      }).catch(e =>{
        Swal.close();
        if( e.response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if( e.response.status == 404){ app.$router.push({ name: 'Error-404' }); }
      });
    },

    getRecords(){
      Swal.fire({
        title: "Loading data.",
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;
      let ent = (this.entity == 'payout') ? "payments" : this.entity + "s";
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/'+ent.replace(" ","").replace("-", "")+'.php',
        {
          params: {
            request: 'get'+this.capitalLetter(ent).replace(" ",""),
            token: this.user.token
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.tableData = response.data;
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
        }
      }).catch(e =>{
        Swal.close();
        if( e.response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if( e.response.status == 404){ app.$router.push({ name: 'Error-404' }); }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    capitalLetter(string) {
      return ( string.charAt(0).toUpperCase() + string.slice(1) ).replace("-", " ");
    },

    setBreadcrum(){
      this.items = [
        {text: this.menu, href:"#/"},
        {text: this.title.replace(" log", "")+"s", active:true}
      ]
    },

    setInit(){
      this.fields     = [];
      this.tableData  = [];
      this.title      = this.capitalLetter(this.checkOtherEntities(this.entity)) + " log";
      this.tableName  = this.capitalLetter(this.checkOtherEntities(this.entity)) + " records.";
      this.addAction  = this.addLink;

      //console.log(this.entity.replace("-",""));

      this.setBreadcrum();
      this.getFields();
      this.getRecords();
    },

    toggleAccount(idCompany, action){
      let app = this;
      var task = (action == 'disable') ? 'disable' : 'enable';
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/organizations.php',
        {
          params: {
            request: task+'Account',
            id: idCompany,
            token: this.user.token
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( !isNaN(response.data ) ){
            Swal.fire("GREATE!", "Account"+task+"d manually with success.", "success")
            .then(function(){
              app.$router.go();
            });
          }else{
            Swal.fire("Ooops!", "There was an error trying to disable this account. Please try again.", "error");
          }
        }else{
          Swal.fire("Ooops!", "There was an error trying to disable this account. Please try again.", "error");
        }
      }).catch(e =>{
        Swal.fire("Ooops!", "There was an error trying to disable this account. Please try again. Error Code: "+e, "error");
      });
    },

    openExternals(externalAppLink){
      window.location.href = externalAppLink;
      return false;
    }
  }
};
</script>

<template :key="componentKey">
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row" >
      <div class="col-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <h4 class="card-title">{{tableName}}</h4>
              </div>
              <div class="col-lg-6 text-md-right">
                <router-link v-if="addAction != ''" :to="addAction">
                  <span class="btn btn-success"><i class="fas fas fa-plus"></i> Add {{capitalLetter(this.checkOtherEntities(entity))}}</span>
                </router-link>

                <!--
                <export-json-excel
                  v-if="tableData.length > 0"
                  :data="tableData"
                  class="btn btn-outline-dark ml-2"
                >
                  <span title="Download spreadsheet report">
                    <b-badge variant="dark"><i class="fas fa-download"></i></b-badge>
                    <b-badge variant="dark" class="ml-1"><i class="fas fa-file-excel"></i></b-badge>
                  </span>
                </export-json-excel>
                -->

                <button v-if="user.user.id_role == 12" class="btn btn-outline-dark ml-3" title="Download spreadsheet report" @click="downloadJSON">
                  <b-badge variant="dark"><i class="fas fa-download"></i></b-badge>
                  <b-badge variant="dark" class="ml-1"><i class="fas fa-file-excel"></i></b-badge>
                </button>
              </div>
            </div>
            
            <div>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :hover="true"
                  small
                >
                  <template v-slot:cell(status)="row">
                    <strong>
                      <span class="text-success" v-if="row.item.status == 'ACTIVE'">{{row.item.status}}</span>
                      <span class="text-danger" v-else>{{row.item.status}}</span>
                    </strong>
                  </template>
                  <template v-slot:cell(de_status)="row">
                    <span v-if="row.item.de_status=='NEW' || row.item.de_status=='NEW'" class="badge badge-light">{{row.item.de_status}}</span>

                    <span v-if="row.item.de_status=='IN PROGRESS' || row.item.de_status=='In Progress'" class="badge badge-info">{{row.item.de_status}}</span>

                    <span v-if="row.item.de_status=='ON HOLD' || row.item.de_status=='On Hold'" class="badge badge-warning">{{row.item.de_status}}</span>

                    <span v-if="row.item.de_status=='COMPLETED' || row.item.de_status=='Completed'" class="badge badge-success">{{row.item.de_status}}</span>

                    <span v-if="row.item.num_vin == ''" class="badge badge-primary">QUICK ORDER</span>

                    <b-progress v-if="row.item.de_status == 'COMPLETED' && row.item.progress" show-value height="10px" :value="row.item.progress" :max="100" class="mt-2" variant="success"></b-progress>
                    <b-progress v-else-if="row.item.de_status=='IN PROGRESS' && row.item.progress" show-value height="10px" :value="row.item.progress" :max="100" class="mt-2" variant="info"></b-progress>
                    <b-progress v-else-if="row.item.de_status=='ON HOLD' && row.item.progress" show-value height="10px" :value="row.item.progress" :max="100" class="mt-2" variant="warning"></b-progress>
                  </template>

                  <template v-slot:cell(de_customerEmail)="row">
                    <a href="javascript:void(0);" @click="openExternals('mailto:'+row.item.de_customerEmail)">{{row.item.de_customerEmail}}</a>
                  </template>
                  <template v-slot:cell(num_customerPhoneNumber)="row">
                    <a href="javascript:void(0);" @click="openExternals('tel:'+row.item.num_customerPhoneNumber)">{{row.item.num_customerPhoneNumber}}</a>
                  </template>

                  <template v-slot:cell(actions)="row">
                    <button v-if="actions.view" size="sm" class="mr-1 btn btn-sm btn-info" @click="$router.push({ name: `${entity}`+'View', params: { id: `${row.item['id_'+entity.replace('-','')]}` } })">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button v-if="actions.edit" size="sm" class="mr-1 btn btn-sm btn-warning" @click="$router.push({ name: `${entity}`+'Edit', params: { id: `${row.item['id_'+entity.replace('-','')]}` } })">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button v-if="actions.start" size="sm" class="mr-1 btn btn-sm btn-primary" @click="$router.push({ name: `${entity}`+'Play', params: { id: `${row.item['id_'+entity.replace('-','')]}` } })">
                      <i class="fas fa-play"></i>
                    </button>
                    <button v-if="actions.profile" size="sm" class="mr-1 btn btn-sm btn-dark" @click="$router.push({ name: `${entity}`+'View', params: { id: `${row.item['num_vin']}` } })">
                      <i class="fas fa-folder-open"></i>
                    </button>

                    <button v-if="actions.invoice" size="sm" class="mr-1 btn btn-sm btn-light" @click="sendInvoice(`${row.item['id_workorder']}`)">
                      <i class="ri-mail-send-line"></i>
                    </button>
                    <button v-if="actions.download" size="sm" class="mr-1 btn btn-sm btn-dark" @click="downloadInvoice(`${row.item['invoice']}`)">
                      <i class="fas fa-file-download"></i>
                    </button>
                    <button
                      v-if="actions.disable"
                      size="sm"
                      class="mr-1 btn btn-sm btn-secondary"
                      :title="(row.item.de_companyStatus >= 1) ? 'Disable account' : 'Enable Account'"
                      @click="toggleAccount(row.item.id_company, (row.item.de_companyStatus >= 1) ? 'disable' : 'enable')"
                    >
                      <i v-if="row.item.de_companyStatus >= 1" class="mdi mdi-cancel"></i>
                      <i v-else class="fas fa-check-square"></i>
                    </button>
                    <button v-if="user.user.id_role <= 2" size="sm" class="mr-1 btn btn-sm btn-danger">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    <!-- info(row.item, row.index, $event.target) -->
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style type="text/css">
.add-button {
  font-family: "Inter", sans-serif !important;
  font-size: 15px !important;
  margin: 0 0 7px 0;
  font-weight: 500;
}
.actionsFixed{
  width: 200px !important;
}
.table thead th {
    vertical-align: middle !important;
}
</style>