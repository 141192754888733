<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from 'axios';

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Attendance registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader},
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title:"",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Attendances",
          href: "#/attendances"
        },
        {
          text: "Attendance",
          active: true
        }
      ],

      componentKey:         0,
      load:                 0,
      pools:                [],
      coaches:              [],
      groups:               [],
      members:              [],
      attendances:          [],
      selectedPool:         "",
      selectedCoach:        "",
      selectedGroup:        "",

      server:               "",

      form: {
        id_pool:            "",
        id_group:           "",
        id_schedule:        "",
        id_coach:           "",
      },
      validToken: false,
      options:{
        format: 'YYYY-MM-DD',
      }
    };
  },
  validations: {
    form: {
      id_pool:            { required },
      id_coach:           { required },
      id_group:           { required },
    }
  },
  watch:{
    load: function(){
      let app = this;
      if(this.load == 4 ){
        this.pools.forEach(function(pool){
          if(app.form.id_pool == pool.id_pool){
            app.selectedPool = pool;
          }
        });

        this.coaches.forEach(function(coach){
          if(app.form.id_coach == coach.id_coach){
            app.selectedCoach = coach;
          }
        });
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath+'dist';
    //this.getMembers();
    this.getAttendancesForToday();
    this.getPools();
    this.getCoaches();
  },
  methods: {
    async getAttendancesForToday(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/attendances.php',
        {
          params: {
            request: 'getAttendancesForToday',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            Swal.close();
            this.validToken = false;
          }else{
            this.attendances = response.data;
            //this.getMembers();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load pools data! Please load this module again.", "error");
        }
      });
    },

    async getPools(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/pools.php',
        {
          params: {
            request: 'getPools',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            Swal.close();
            this.validToken = false;
          }else{
            this.pools = response.data;
            this.load++;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load pools data! Please load this module again.", "error");
        }
      });
    },

    async getCoaches(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/coaches.php',
        {
          params: {
            request: 'getCoaches',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.coaches = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load coaches data! Please load this module again.", "error");
        }
      });
    },

    async getScheduleByPoolAndCoachID(){
      let app = this;

      if(!this.$route.params.id){
        this.$bvToast.toast(
          'Loading '+this.selectedCoach.nm_coach+' '+this.selectedCoach.nm_lastName+' coach schedules.',
          {
            title: 'Loading...',
            variant: 'info',
            solid: true
          }
        );
      }

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/schedules.php',
        {
          params: {
            request: 'getScheduleByPoolAndCoachID',
            id_pool: this.selectedPool.id_pool,
            id_coach: this.selectedCoach.id_coach,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(response.data.length > 0){
            this.groups = response.data;
            this.load++;

            if(this.$route.params.id){
              this.groups.forEach(function(group){
                if(app.form.id_group == group.id_group){
                  app.selectedGroup = group;
                }
              });
            }else{
              this.$bvToast.toast(
                'Schedules successfully loaded.',
                {
                  title: 'Done',
                  variant: 'success',
                  solid: true
                }
              );
            }
          }else{
            this.$bvToast.toast(
              'No groups found associated to the selected pool/coach, please try again or try with another pool/coach.',
              {
                title: 'Done',
                variant: 'warning',
                solid: true
              }
            );
          } 
        }else{
          this.$bvToast.toast(
            "Ooops!", "Seems like some error ocurr, please try again.",
            {
              title: 'Loading error',
              variant: 'error',
              solid: true
            }
          );
        }
      });
    },

    async getMembers(){
      let app         = this;
      let currentDate = new Date().toISOString().slice(0, 10);

      var data = {
        request:  (this.selectedGroup.id_group) ? 'getGroupByID': 'getMembersForMultipleSelect',
        token:    this.user.token,
      }
      if( this.selectedGroup.id_group ){
        data.id = this.selectedGroup.id_group;
      }

      axios
      .get(
        (this.selectedGroup.id_group) ? window.serverPath+'dist/mvw/controllers/groups.php' : window.serverPath+'dist/mvw/controllers/members.php',
        {
          params: data
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.members = (this.selectedGroup.id_group) ? response.data.de_members : response.data;
          this.load++;

          if( this.attendances.length > 0 ){
            this.members.forEach(function(member){
              app.attendances.forEach(function(attendance){
                if(member.id_member == attendance.id_member){
                  if(currentDate == attendance.dt_attendanceDate){
                    member.checked = true;
                  }
                }
              });
            });
          }
          
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    checkAssistance(member){
      this.form.id_pool       = member.id_pool;
      this.form.id_coach      = member.id_coach;
      this.form.id_group      = member.id_group;
      this.form.id_member     = member.id_member;
      this.form.id_schedule   = this.selectedGroup.id_schedule;

      this.members.forEach(function(person){
        if(person.id_member == member.id_member){
          person.checked = true;
          return person;
        }
      });

      this.componentKey ++;

      // stop here if form is invalid
      this.$v.$touch();

      if( !this.$v.form.$error ){
        Swal.fire({
          title: "Saving attendance.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/attendances.php', {
          request: 'saveAttendance',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.status == 200 ){
            if( response.data.error ){
              Swal.fire("Ooops!" + response.data.error, "Seems like some error ocurr, please try again.", "error");
            }else{
              Swal.fire("Good job!", "Attendance for "+ member.name +" is saved!", "success");
            }
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }else{
        console.log(this.$v);
      }
    },

    rowClicked(item) {
      let app = this;

      if(!item._rowVariant){
        if(item.selected){
          let index = 0;
          console.log('selected');

          this.$set(item, 'selected', false);
          delete item._rowVariant;
          
          this.selectedMembers.forEach(function(member){
            if(member.id_member == item.id_member){
              app.selectedMembers.splice(index, 1);
            }
            index ++;
          });

        }else{
          this.$set(item, 'selected', true);
          this.selectedMembers.push(item);
        }
      }
    },

    tbodyRowClass(item) {
      /* Style the row as needed */
      if(item.selected){
        //return ["b-table-row-selected", "table-primary", "cursor-pointer"]
        return ["b-table-row-selected", "table-active"];
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveMember">
              <div class="row">
                <div class="col-sm-7">
                  <h4 class="card-title">{{ $t('menuitems.attendances.list.personalData') }}</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_pool">{{ $t('menuitems.attendances.list.pool') }}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': $v.form.id_pool.$error }"
                          name="id_pool"
                          id="id_pool"
                          v-model="selectedPool"
                        >
                          <option value="">{{ $t('menuitems.coaches.list.selectPools') }}</option>
                          <option v-for="(pool, index) in pools" :key="index" :value="pool">{{pool.nm_pool}}</option>
                        </select>
                        <div v-if="$v.form.id_pool.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_pool.required">Pool is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_coach">{{ $t('menuitems.attendances.list.coach') }}</label>
                        <select
                          class="form-control"
                          name="id_coach"
                          id="id_coach"
                          v-model="selectedCoach"
                          :class="{ 'is-invalid': $v.form.id_coach.$error }"
                          @change="getScheduleByPoolAndCoachID"
                        >
                          <option value="">{{ $t('menuitems.members.list.selectCoach') }}</option>
                          <option v-for="(coach, index) in coaches" :key="index" :value="coach">{{coach.nm_coach + " " + coach.nm_lastName}}</option>
                        </select>
                        <div v-if="$v.form.id_coach.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_coach.required">Coach is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_group">{{ $t('menuitems.attendances.list.group') }}</label>
                        <select
                          class="form-control"
                          name="id_group"
                          id="id_group"
                          v-model="selectedGroup"
                          :class="{ 'is-invalid': $v.form.id_group.$error }"
                          @change="getMembers"
                        >
                          <option value="">{{ $t('menuitems.members.list.selectGroup') }}</option>
                          <option v-for="(group, index) in groups" :key="index" :value="group">{{group.schedule}}</option>
                        </select>
                        <div v-if="$v.form.id_group.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_group.required">Group is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-5" :key="componentKey">
                  <h4>{{$t('menuitems.attendances.list.checkTitle')}}<br></h4>
                  <h5><i>({{new Date().toDateString()}})</i></h5>
                  <b-table
                    class="mt-3"
                    sticky-header
                    small
                    :items="members"
                    :fields="['name', 'actions']"
                    responsive="sm"
                    style="max-height: 450px !important;"
                  >
                  <template v-slot:cell(actions)="row">
                    <span v-if="row.item['checked']" class="btn-sm btn-info">{{ $t('menuitems.attendances.list.checked') }}</span>
                    <span v-else class="btn btn-sm btn-dark" @click="checkAssistance(row.item)">{{ $t('menuitems.attendances.list.checkAttendance') }}
                      <i class="fas fa-check" style="cursor: pointer;"></i>
                    </span>
                    <!-- info(row.item, row.index, $event.target) -->
                  </template>
                  </b-table>
                </div>
              </div>

              <!--
              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE ATTENDANCE</button>
                    <router-link to="/members" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
              -->
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-input-tag-wrapper {
    height: auto !important;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #5664d2 !important;
    border: 1px solid #5664d2 !important;
    color: #fff !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff !important;
  }
</style>