<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from "axios"
import Multiselect from "vue-multiselect";

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Training registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      info: null,
      title: "",
      componentKey: 0,
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Trainings",
          href: "#/trainings"
        },
        {
          text: "Training",
        }
      ],
      routine: null,
      routines: [],
      periods: ["Seconds (s)", "Minutes (m)", "Hours (h)", "Times (t), Metters (mts)"],
      weekDays:   ["MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"],
      //meassures: ["Serie", "Round", "Time"],
      meassures: ["Days (d)", "Weeks (w)", "Months (m)"],
      units: ["Kilograms (kg)", "Metters (mts)", "Miles (mi)", "Kilometters (kms)"],
      validVideo: false,
      validToken: true,
      form: {
        nm_training: '',
        de_trainingDescription: '',
        de_trainingObjective: '',
        selectedRoutines: []
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      nm_training:              { required },
      de_trainingDescription:   { required },
      de_trainingObjective:     { required },
    }
  },
  watch:{
    validToken(newValue){
      console.log(newValue);
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    /*
    let app     = this;
    let index   = 0;
    let select  = document.getElementById("de_meassure");

    //EventListener of language change. If changed, then update the days of the week from EN to ES and viceversa
    window.addEventListener('language-changed', (event) => {
      if(event.detail.language == 'es'){
        app.meassures = ["Días (d)", "Semanas (w)", "Meses (m)"];
      }else{
        app.meassures = ["Days (d)", "Weeks (w)", "Months (m)"];
      }
      //Clearing the options
      select.options.length = 0;

      app.meassures.forEach(function(day){
        //Adding new options
        select.options[select.options.length] = new Option(day, index);
        index++;
      });
      index = 0;
    });
    */
    this.getRoutines();

    if( this.$route.params.id ){
      this.getTrainingByID();
    }
  },
  methods: {
    async getRoutines(){
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/routines.php',
        {
          params: {
            request: 'getRoutinesForMultipleSelect',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            if(Array.isArray(response.data)){
              app.routines = response.data;
            }
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
        }
      });
    },
    async getTrainingByID(){
      Swal.fire({
        title: "Loading training data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/trainings.php',
        {
          params: {
            request: 'getTrainingByID',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.training = response.data;

            this.form.id_training             = app.training.id_training;
            this.form.de_trainingDescription  = app.training.de_trainingDescription;
            this.form.de_trainingObjective    = app.training.de_trainingObjective;
            this.form.nm_training             = app.training.nm_training;
            this.form.selectedRoutines        = JSON.parse(app.training.de_selectedRoutines);
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveTraining(e) {
      let app = this;

      e.preventDefault();

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_training = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving training.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/trainings.php', {
          request: 'saveTraining',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your training is correct "+action+"!", "success");
          app.$router.push({ name: 'trainings' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
    setPosition(event){
      this.form.num_latitude = event.lat;
      this.form.num_longitude = event.lng;
    },
    validateYouTubeUrl(e){
      var url = e.target.value;
      if (url != undefined && url != '') {        
          var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
          var match = url.match(regExp);
          if (match && match[2].length == 11) {
            this.validVideo = true;
            var element = document.getElementById('youtubevideo');
            // Do anything for being valid
            // if need to change the url to embed url then use below line            
            element.src = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1';
          } else {
            this.validVideo = false;
            Swal.fire("Ooops", "This is not a valid youtube video, please verify the link.", "error");
            // Do anything for not being valid
          }
      }else{
        this.validVideo = false;
      }
    },
    setQuantity(event){
      console.log(event.target.value);
      this.form.selectedRoutines[event.target.id].quantity = event.target.value;
      //console.log(this.selectedRoutines(index).quantity);
    },
    sumToDate(index, type, quantity){
      if(this.form.selectedRoutines[index].dt_startingDate != ''){
        //var parts = stringDate.split("-");
        //var date = parts[0]+"-"+parts[1]+"-"+parts[2];
        var date1 = new Date( this.form.selectedRoutines[index].dt_startingDate );
        switch (type){
          case "Days (d)":    date1.setDate(date1.getDate() + parseInt(quantity) ); break;
          case "Weeks (w)":   date1.setDate(date1.getDate() + (quantity * 7) ); break;
          case "Months (m)":  date1.setMonth(date1.getMonth() + parseInt(quantity) ); break;
        }

        this.form.selectedRoutines[index].dt_endingDate = date1.toISOString().split("T")[0];
        this.$forceUpdate();
      }else{
        this.form.selectedRoutines[index].dt_endingDate = "";
      }
    },
    showDescription(item){
      //this.routine = item;
      this.routine = {...item};
      this.$bvModal.show('routineDescription');
    },
    resetRoutine(){
      this.routine = null;
    }
    /*
    sumWeeks(stringDate, weeks){
      var parts = stringDate.split("-");
      var date = parts[0]+"-"+parts[1]+"-"+parts[2];
      var date1 = new Date(date);
      date1.setDate(date1.getDate() + (weeks*7) );
      return (date1.toISOString().split("T"))[0];
    },
    sumMonths(stringDate, months){
      //var parts = stringDate.split("-");
      //var date = parts[0]+"-"+parts[1]+"-"+parts[2];
      var date1 = new Date(stringDate);
      
      return (date1.toISOString().split("T"))[0] ;
    },
    
    consoleThis(index, meassure){
      if(meassure.indexOf("Weeks") != -1){
        this.form.selectedRoutines[index].dt_endingDate = this.sumWeeks(this.form.selectedRoutines[index].dt_startingDate, this.form.selectedRoutines[index].quantity);
      }else{
        this.form.selectedRoutines[index].dt_endingDate = this.sumMonths(this.form.selectedRoutines[index].dt_startingDate, this.form.selectedRoutines[index].quantity);
      }
      this.$forceUpdate();
    }
    */
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveTraining">
              <div class="row">
                <div class="col-sm-3">
                  <h4 class="card-title">{{ $t('menuitems.trainings.list.trainingData') }}</h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_training">{{ $t('menuitems.trainings.list.name') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_training.$error }"
                          name="nm_training"
                          id="nm_training"
                          v-model="form.nm_training"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_training.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_training.required">Training name is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_trainingDescription">{{ $t('menuitems.trainings.list.description') }}</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_trainingDescription.$error }"
                          name="de_trainingDescription"
                          id="de_trainingDescription"
                          v-model="form.de_trainingDescription"
                          rows="3"
                          value=""
                        >
                        </textarea>
                        <div v-if="submitted && $v.form.de_trainingDescription.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_trainingDescription.required">Description field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_trainingObjective">{{ $t('menuitems.trainings.list.objective') }}</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_trainingObjective.$error }"
                          name="de_trainingObjective"
                          id="de_trainingObjective"
                          v-model="form.de_trainingObjective"
                          rows="3"
                          value=""
                        >
                        </textarea>
                        <div v-if="submitted && $v.form.de_trainingObjective.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_trainingObjective.required">Description field is required.</span>
                        </div>
                      </div>

                       <div class="form-group">
                        <label for="de_routines">{{ $t('menuitems.trainings.list.routines') }}</label>
                        <multiselect
                          id="id_trainings"
                          v-model="form.selectedRoutines"
                          :options="routines"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :placeholder="$t('menuitems.trainings.list.selectRoutines')"
                          label="label"
                          track-by="label"
                          :preselect-first="false"
                        ></multiselect>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-sm-9">
                  <h4 class="card-title">&nbsp;</h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-md-12">
                     
                      <div class="table-responsive" v-show="form.selectedRoutines.length>0">
                        <table class="table mb-0 table-bordered table-striped" :fixed="true" style="min-width: 300px !important;">
                          <thead class="thead-dark">
                            <tr>
                              <th>{{ $t('menuitems.trainings.list.routine') }}</th>
                              <th>{{ $t('menuitems.trainings.list.quantity') }}</th>
                              <th>{{ $t('menuitems.trainings.list.meassure') }}</th>
                              <th>{{ $t('menuitems.trainings.list.startingDate') }}</th>
                              <th>{{ $t('menuitems.trainings.list.endingDate') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(routine, index) in form.selectedRoutines" :key="index">
                              <td scope="row" class="pt-3">
                                <button type="button" class="btn btn-sm btn-outline-dark mr-1" @click="showDescription(routine)"><i class="fas fa-eye"></i></button>
                                {{routine.label}}
                              </td>
                              <td>
                                <input class="form-control-sm" type="number" v-model="form.selectedRoutines[index].quantity" :id="index">
                              </td>
                              <td>
                                <select
                                  class="form-control-sm"
                                  name="de_meassure"
                                  id="de_meassure"
                                  v-model="form.selectedRoutines[index].meassure"
                                  @change="sumToDate(index, form.selectedRoutines[index].meassure, form.selectedRoutines[index].quantity)"
                                >
                                  <option value="">{{ $t('menuitems.trainings.list.selectMeassure') }}</option>
                                  <option v-for="(meassure, index) in meassures" :key="index" :value="meassure">{{meassure}}</option>
                                </select>
                              </td>
                              <td>
                                <b-form-group id="startingDate">
                                  <b-form-input class="form-control-sm" id="date" value="" format="YYYY/mm/dd" v-model="form.selectedRoutines[index].dt_startingDate" @change="sumToDate(index, form.selectedRoutines[index].meassure, form.selectedRoutines[index].quantity)" type="date"></b-form-input>
                                </b-form-group>
                              </td>
                              <td class="pt-3">
                                <span class="font-size-13">{{form.selectedRoutines[index].dt_endingDate}}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <b-modal v-if="routine != null" id="routineDescription" large hide-footer size="lg" scrollable :title="this.routine.label" title-class="font-18" @close="resetRoutine" centered>
                  <p>{{routine.de_routineDescription}}</p>
                  <div class="row">
                    <div class="col-sm-12">
                      <p><strong class="font-size-16">Días: </strong><br>
                        <span v-for="(day, index) in JSON.parse(routine.de_selectedDays)" :key="index">{{weekDays[day]}} - </span>
                      </p>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-sm-12" v-for="(set, index) in JSON.parse(routine.de_selectedSets)" :key="index">
                        <div class="row"><b>{{set.label}}</b></div>
                        <table class="table mb-4 table-bordered table-striped" :fixed="true" style="min-width: 300px !important;">
                          <thead class="thead-dark">
                            <tr>
                              <th>{{ $t('menuitems.routines.list.exercise') }}</th>
                              <th>{{ $t('menuitems.exercises.list.description') }}</th>
                              <th>{{ $t('menuitems.sets.list.quantity') }}</th>
                              <th>{{ $t('menuitems.sets.list.meassure') }}</th>
                              <th>{{ $t('menuitems.sets.list.time') }}</th>
                              <th>{{ $t('menuitems.sets.list.unit') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(exercise, index) in JSON.parse(set.de_selectedExercises)" :key="index">
                              <td scope="row" class="pt-3">{{exercise.label}}</td>
                              <td class="pt-3">{{exercise.de_exerciseDescription}}</td>
                              <td class="pt-3">{{exercise.quantity}}</td>
                              <td class="pt-3">{{exercise.meassure}}</td>
                              <td class="pt-3">{{exercise.timesQuantity}} {{exercise.time}}</td>
                              <td class="pt-3">{{exercise.unitsQuantity}} {{exercise.unit}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{ $t('menuitems.trainings.list.save') }}</button>
                    <router-link to="/trainings" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{ $t('menuitems.trainings.list.cancel') }}</button>
                    </router-link>
                  </div>
                </div>
              </div>
          </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    border: solid 1px!important;
    border-color:#b1bbc4 !important;
    width:100% !important;
}
</style>