<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from "axios"

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Exercise registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      info: null,
      title: "",
      componentKey: 0,
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Exercises",
          href: "#/exercises"
        },
        {
          text: "Exercise",
          active: true
        }
      ],
      exercises: [],
      validVideo: false,
      validToken: true,
      form: {
        nm_exercise: '',
        de_exerciseDescription: '',
        url_video: '',
        url_photograph: '',
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      nm_exercise:  { required },
    }
  },
  watch:{
    validToken(newValue){
      console.log(newValue);
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      this.getExerciseByID();
    }
  },
  methods: {
    getExerciseByID(){
      Swal.fire({
        title: "Loading exercise data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/exercises.php',
        {
          params: {
            request: 'getExerciseByID',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          app.exercise = response.data;

          this.form.id_exercise             = app.exercise.id_exercise;
          this.form.de_exerciseDescription  = app.exercise.de_exerciseDescription;
          this.form.nm_exercise             = app.exercise.nm_exercise;
          this.form.url_video               = app.exercise.url_video;
          this.form.url_photograph          = app.exercise.url_photograph;

          this.validVideo = true;
          document.getElementById('youtubevideo').src = app.exercise.url_video;
          
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveExercise(e) {
      let app = this;

      e.preventDefault();

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_exercise = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving exercise.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/exercises.php', {
          request: 'saveExercise',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your exercise is correct "+action+"!", "success");
          app.$router.push({ name: 'exercises' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
    setPosition(event){
      this.form.num_latitude = event.lat;
      this.form.num_longitude = event.lng;
    },
    validateYouTubeUrl(e){
      var url = e.target.value;
      if (url != undefined && url != '') {        
          var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
          var match = url.match(regExp);
          if (match && match[2].length == 11) {
            this.validVideo = true;
            var element = document.getElementById('youtubevideo');
            // Do anything for being valid
            // if need to change the url to embed url then use below line            
            element.src = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1';
          } else {
            this.validVideo = false;
            Swal.fire("Ooops", "This is not a valid youtube video, please verify the link.", "error");
            // Do anything for not being valid
          }
      }else{
        this.validVideo = false;
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveExercise">
              <div class="row">
                <div class="col-sm-6">
                  <h4 class="card-title">{{ $t('menuitems.exercises.list.exerciseData') }}</h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_exercise">{{ $t('menuitems.exercises.list.name') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_exercise.$error }"
                          name="nm_exercise"
                          id="nm_exercise"
                          v-model="form.nm_exercise"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_exercise.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_exercise.required">Exercise name is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_exerciseDescription">{{ $t('menuitems.exercises.list.description') }}</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_exerciseDescription.$error }"
                          name="de_exerciseDescription"
                          id="de_exerciseDescription"
                          v-model="form.de_exerciseDescription"
                          rows="3"
                          value=""
                        >
                        </textarea>
                        <div v-if="submitted && $v.form.de_exerciseDescription.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_exerciseDescription.required">Description field is required.</span>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <h4 class="card-title">&nbsp;</h4>
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="url_video">{{ $t('menuitems.exercises.list.videoLink') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          name="url_video"
                          id="url_video"
                          v-model="form.url_video"
                          value=""
                          @change="validateYouTubeUrl"
                        >
                      </div>
                      <div v-show="validVideo" class="form-group embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" id="youtubevideo" src=""></iframe>
                      </div>

                      <div class="form-group">
                        <label for="url_photograph">{{ $t('menuitems.exercises.list.photographLink') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          name="url_photograph"
                          id="url_photograph"
                          v-model="form.url_photograph"
                          value=""
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{ $t('menuitems.exercises.list.save') }}</button>
                    <router-link to="/exercises" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{ $t('menuitems.exercises.list.cancel') }}</button>
                    </router-link>
                  </div>
                </div>
              </div>
          </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>