<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';

import {
  required,
  //email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403 },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Company",
          href: "#/"
        },
        {
          text: "Departments",
          href: "#/company/departments"
        },
        {
          text: "Department",
          active: true
        }
      ],
      form: {
        id_company: "",
        id_site: "",
        nm_department: "",
      },
      submitted: false,
      user: {},
      companies: [],
      sites: [],
      validToken: true,
      validAccess: true,
    };
  },
  validations: {
    form: {
      id_company: { required },
      id_site: { required },
      nm_department: {required, minLength: minLength(2), maxLength: maxLength(50)},
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      this.getDepartment();
    }else{
      this.getCompanies();
    }
  },
  methods: {
    async getCompanies(){
      let app = this;

      Swal.fire({
        title: "Loading Companies",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/organizations.php',
        {
          params: {
            request: 'getOrganizationsForCombos',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            this.validToken = false;
          }else{
            app.companies = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },
    async getSitesByCompanyID(){
      let app = this;

      Swal.fire({
        title: "Loading Sites",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
         window.serverPath+'dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSitesByCompanyID',
            idCompany: this.form.id_company,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.sites = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    getDepartment(){
      let app = this;

      Swal.fire({
        title: "Loading Departments",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
         window.serverPath+'dist/mvw/controllers/departments.php',
        {
          params: {
            request: 'getDepartment',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            app.validToken = false;
          }else{
            app.departments = response.data;

            this.form.id_company    = app.departments.id_company;
            this.form.id_site       = app.departments.id_site;
            this.form.id_department = app.departments.id_department;
            this.form.nm_department = app.departments.nm_department

            if( this.$route.params.id ){
              this.form.id_department     = app.departments.id_department;
            }

            Swal.close();
            
            this.getCompanies();
            this.getSitesByCompanyID();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveDepartment(e) {
      let app = this;

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_department = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving department.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post( window.serverPath+'dist/mvw/controllers/departments.php', {
          request: 'saveDepartment',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            app.validToken = false;
          }else{
            let action = "";

            if(response.status == 500){ app.$router.push({ name: 'Error-500' }); }
            if(response.status == 404){ app.$router.push({ name: 'Error-404' }); }
            if(response.status == 401){ app.$router.push({ name: 'Error-401' }); }
            
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "Your department is correct "+action+"!", "success");
            app.$router.push({ name: 'departments' });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });

        /*
        Swal.fire("Good job!", "Your site is saved now!", "success")
        .then(() => {
          self.submitted = false;
          self.form.id_site       = "";
          self.form.nm_department = "";
        });
        */
      }
    },

    resetSite(){
      this.form.id_site = "";
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveDepartment">
              <div class="row">
                <div class="col-lg-4" style="border-right: solid 1px #c8c8c8;">
                  <h4 class="card-title">{{$t('menuitems.departments.list.departmentData')}}</h4>
                  <p class="card-title-desc">{{$t('menuitems.departments.list.departmentDataDescription')}}</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_company">{{$t('menuitems.departments.list.company')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_company.$error }"
                          name="id_company"
                          id="id_company"
                          v-model="form.id_company"
                          @change="resetSite();getSitesByCompanyID()"
                        >
                          <option value="">{{$t('menuitems.departments.list.selectCompany')}}</option>
                          <option v-for="(company, index) in companies" :key="index" :value="company.id_company">{{company.nm_company}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_company.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_company.required">Company field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_site">{{$t('menuitems.departments.list.site')}}</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_site.$error }"
                          name="id_site"
                          id="id_site"
                          v-model="form.id_site"
                          change="getDepartmentsBySiteID"
                        >
                          <option value="">{{$t('menuitems.departments.list.selectSite')}}</option>
                          <option v-for="(site, index) in sites" :key="index" :value="site.id_site">{{site.nm_site}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_site.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_site.required">Site is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_department">{{$t('menuitems.departments.list.departmentName')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_department.$error }"
                          name="nm_department"
                          id="nm_department"
                          v-model="form.nm_department"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_department.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_department.required">Department name is required.</span>
                          <span v-if="!$v.form.nm_department.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_department.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <h4 class="card-title">{{$t('menuitems.departments.list.importance')}}</h4>
                  <p class="card-title-desc">{{$t('menuitems.departments.list.importanceDescription')}}</p>
                </div>

                <div class="col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <img src="@/assets/images/organization_chart2.jpg" class="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.departments.list.save')}}</button>
                    <router-link to="/company/departments" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.departments.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>