<script type="text/javascript" src="cordova.js"></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import InputTag from 'vue-input-tag';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import error403 from '../utility/error-403';

import { VueEditor } from "vue2-editor";
//import Basic from "./basic";

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Site registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, InputTag, VueEditor, error403, VuePhoneNumberInput /*Basic*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "Site registration",
      componentKey: 0,
      num_lat: 0.0,
      num_lng: 0.0,
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Sites",
          href: "/company/sites"
        },
        {
          text: "Site",
          active: true
        }
      ],
      form: {
        nm_company:               "",
        de_companySlogan:         "",
        de_companyShortAddress:   "",
        de_companyFullAddress:    "",
        de_companyAbout:          "",
        de_companyContactEmail:   "",
        de_companyHistory:        "",
        de_companyIndustry:       "",
        de_companyCapabilities:   "",
        num_companyContactPhone:  "",
        de_companyTags:           [],
        url_facebook:             "",
        url_twitter:              "",
        url_instagram:            "",
        url_linkedin:             "",
        url_youtube:              "",
        de_phoneNumber:           "",
        url_photograph:           "",
        num_rfc:                  "",
      },

      translations:{
        countrySelectorLabel: 'Código de País',
        countrySelectorError: 'Seleccionar ún País',
        phoneNumberLabel: 'Teléfono',
        example: 'Ejemplo'
      },
      server:                     "",
      platform:                   "",
      defaultCountryCode:         "MX",
      submitted:                  false,
      canEdit:                    true,
      isEditState:                false,
      validToken:                 true,
      validAccess:                true,
      text:                       "",
      content:                    ""
    };
  },
  validations: {
    form: {
      de_companySlogan: { required },
      de_companyShortAddress: { required },
      de_companyFullAddress: { required },
      de_companyAbout: { required, minLength: minLength(100), maxLength: maxLength(200) },
      de_companyContactEmail: { required, email },
      num_companyContactPhone: { required },
      num_rfc: { required },
    }
  },
  watch:{
    lat(newLat, oldLat){
      if(newLat != oldLat){
        this.lat = newLat;
        this.forceRerenderMap();
      }
    },
    lng(newLng, oldLng){
      if(newLng != oldLng){
        this.lng = newLng;
        this.forceRerenderMap();
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath;
    document.addEventListener("deviceready", this.onDeviceReady, false);

    this.getCompanByID();
  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform = platform;
      }
    },

    setSite(){
      this.form.id_country              = "MEX";
      this.form.id_state                = "BC";
      this.form.de_address              = "CONOCIDA EN LA CIUDAD DE ENSENADA. COLONIA PUNTA BANDA III, EDIFICIO ROSA, SEGUNDO PISO, #373-3";
      this.form.de_emailRepresentant    = "a@a.com";
      this.form.de_emailHumanResources  = "b@b.com";
      this.form.de_companyContactEmail         = "c@c.com";
      this.form.nm_siteName             = "MEXICO";
      this.form.sn_isCorporative        = true;

      this.form.de_companyHistory                 = this.text;
      this.form.de_companyIndustry                = this.content;
      this.form.de_companyCapabilities            = this.text + " " + this.content;
      console.log(this.form);
    },
    setEditState(){
      this.isEditState = !this.isEditState;

      /*
      let toolbarSections = document.getElementsByClassName("ql-formats");
      console.log(toolbarSections);
      let listArr = Array.from(toolbarSections);

      listArr.forEach(item => console.log(item.children));
      */
    },

    getCompanByID(){
      Swal.fire({
        title: "Loading Company data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/organizations.php',
        {
          params: {
            request: 'getOrganization',
            id: this.user.user.id_company,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            var company = response.data;
            app.form = company;

            if(company.de_phoneNumber){
              this.form.de_phoneNumber = JSON.parse(company.de_phoneNumber);
              this.defaultCountryCode = this.form.de_phoneNumber.countryCode;
              this.form.num_companyContactPhone = this.form.de_phoneNumber.phoneNumber;
            }else{
              this.form.num_companyContactPhone = company.num_companyContactPhone;
            }

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    clickFile(){
      this.$refs.url_photograph.click();
    },
    handleFile(event){
      let app = this;
      let output = this.$refs.picture;
      let formData = new FormData();

      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function() {
        URL.revokeObjectURL(output.src); // free memory
      }

      formData.append('request',        'changePicture');
      formData.append('token',          this.user.token);
      formData.append('data',           JSON.stringify(this.user.user) );
      formData.append('url_photograph', event.target.files[0]);

      axios.post(window.serverPath+'dist/mvw/controllers/organizations.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        if(response.status == 200){
          Swal.close();
          if( response.error ){
            Swal.fire("Ooops!" + response.error, "Seems like some error ocurr, please try again.", "error");
          }else{
            app.form.url_photograph = response.data;
            Swal.fire("Good job!", "Company logo has been changed successfully!", "success").then(function(response){
              if(response.isConfirmed){
               app.$router.go();
              }
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
      });
    },

    onUpdate(payload){
      this.form.de_phoneNumber = payload;
    },

    saveCompany(){
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){

        Swal.fire({
          title: "Saving customer.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/organizations.php', {
          request: 'saveOrganization',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "Your company data has been "+action+" successfully!", "success")
            .then(function(response){
              if(response.isConfirmed){
               app.$router.go();
              }
            });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div v-if="validAccess">
      <div class="row ml-1 mb-2" v-if="canEdit">
        <button v-if="(user.user.id_role == 12 || user.user.id_role == 1) && !isEditState" @click="setEditState" type="button" class="btn btn-sm btn-primary"><i class="ri-edit-fill"></i> EDIT</button>
        
        <button v-if="isEditState"  @click="saveCompany" type="button" class="btn btn-sm btn-success ml-4"><i class="ri-save-fill"></i> SAVE</button>
        <button v-if="isEditState"  @click="setEditState" type="button" class="btn btn-sm btn-danger ml-4"><i class="ri-save-fill"></i> CANCEL</button>
      </div>

      <div class="row" v-if="!isEditState">
        <div class="col-lg-12">
          <div class="email-leftbar card">
            <div class="text-center">
              <img v-if="form.url_photograph != ''" :src="server+'dist/'+form.url_photograph" width="100px" />
              <img v-else src="@/assets/images/logo.png" width="50%" />
              

            </div>
            <p class="card-title-desc text-center m-0 font-size-16"><b>{{form.nm_company}}</b></p>
            <p class="card-title-desc text-center m-0 font-size-14">{{form.de_companySlogan}}</p>
            <p class="card-title-desc text-center m-0"><small>{{form.de_companyShortAddress}}</small></p>

            <div class="card"></div>

            <div class="mail-list mt-3">
              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>About</b></div>
              <p>{{form.de_companyAbout}}</p>
              

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
              <p>
                <small>
                  <i class="far fa-envelope"></i> {{form.de_companyContactEmail}}
                </small><br>
                
                <small v-if="JSON.stringify(form.de_phoneNumber) == ''">
                  <i class="fas fa-phone-alt"></i> {{form.de_companyPhoneNumber}} <br><br>
                </small>
                
                <small v-if="JSON.stringify(form.de_phoneNumber) != ''">
                  <i class="fas fa-phone-alt"></i> {{form.de_phoneNumber.formatInternational}} <br><br>
                </small>
                
                <small>{{form.de_companyFullAddress}}</small>
              </p>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
              <span v-for="tag in form.de_companyTags" :vid-id="tag" :key="tag" class="badge ml-1 badge-success badge-pill">{{tag}}</span>
            </div>
          </div>

          <div class="card email-rightbar">
            <div class="card-body">
              <b-tabs content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">History</span>
                  </template>
                  <p v-html="form.de_companyHistory"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Industry</span>
                  </template>
                  <p v-html="form.de_companyIndustry"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Capabilities</span>
                  </template>
                  <p v-html="form.de_companyCapabilities"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Social Networks</span>
                  </template>
                  <p></p>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>


      <div class="row" v-if="(user.user.id_role == 12 || user.user.id_role == 1) && isEditState">
        <div class="col-lg-12">
          <div class="email-leftbar card">
            <div class="text-center" style="position: relative;">
              <img v-if="form.url_photograph" :src="server+'dist/'+form.url_photograph" ref="picture" :class="{ 'circular--portrait': !platform, 'circular--portrait--mobile': platform }" style="border: solid 5px #dfe6e9;" width="100%" />
              <img v-else src="@/assets/images/vip.png" ref="picture" :class="{ 'circular--portrait': !platform, 'circular--portrait--mobile': platform }" style="border: solid 5px #dfe6e9;" width="100%" />
              <div class="text-right">
                <img src="@/assets/images/camera_8.png" class="rounded-circle" width="20%" style="border: solid 3px #dfe6e9; margin-top:-50px; margin-right:20px; cursor:pointer; position: relative;" @click="clickFile"/>
                <input type="file" ref="url_photograph" name="url_photograph" style="display: none;" @change="handleFile">
              </div>
            </div>

            <div class="form-group text-center m-1">
              <input
                class="form-control-sm"
                name="de_companySlogan"
                id="de_companySlogan"
                v-model="form.de_companySlogan"
                type="text"
                placeholder="Slogan company"
              />
            </div>
            <div class="form-group text-center m-1">
              <input
                class="form-control-sm"
                name="de_companyShortAddress"
                id="de_companyShortAddress"
                v-model="form.de_companyShortAddress"
                type="text"
                placeholder="City location"
              />
            </div>

            <div class="card"></div>

            <div class="mail-list mt-2">
              <div class="form-group mt-0">
                <label for="de_companyAbout">About</label>
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.de_companyAbout.$error }"
                  name="de_companyAbout"
                  id="de_companyAbout"
                  v-model="form.de_companyAbout"
                  rows="5"
                  value=""
                >
                </textarea>
              </div>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
              <p>
                <input
                  class="form-control-sm"
                  name="de_companyContactEmail"
                  id="de_companyContactEmail"
                  v-model="form.de_companyContactEmail"
                  type="text"
                  :placeholder="$t('menuitems.management.list.companyContactEmail')"
                />
                <br>
                <VuePhoneNumberInput
                  :class="{ 'is-invalid': submitted && $v.form.num_companyContactPhone.$error }"
                  name="num_companyContactPhone"
                  id="num_companyContactPhone"
                  ref="num_companyContactPhone"
                  v-model="form.num_companyContactPhone"
                  :translations="translations"
                  :default-country-code="defaultCountryCode"
                  @update="onUpdate"
                />
                <!--
                <input
                  v-mask="'+(##) ###-###-####'"
                  type="text"
                  class="form-control-sm mt-1"
                  name="num_companyContactPhone"
                  id="num_companyContactPhone"
                  v-model="form.num_companyContactPhone"
                />
                -->
                <br>
                <input
                  class="form-control-sm"
                  :class="{ 'is-invalid': submitted && $v.form.num_rfc.$error }"
                  name="num_rfc"
                  id="num_rfc"
                  v-model="form.num_rfc"
                  type="text"
                  :placeholder="$t('menuitems.management.list.rfc')"
                />
                <br>
              </p>

              <div class="form-group mt-0">
                <label for="de_companyFullAddress">Full Address (for invoicing)</label>
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.de_companyFullAddress.$error }"
                  name="de_companyFullAddress"
                  id="de_companyFullAddress"
                  v-model="form.de_companyFullAddress"
                  rows="5"
                  value=""
                  :placeholder="$t('menuitems.management.list.companyFullAddress')"
                >
                </textarea>
              </div>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
              <input-tag v-model="form.de_companyTags" :limit="10"></input-tag>
            </div>
          </div>

          <div class="card email-rightbar">
            <div class="card-body">
              <b-tabs content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">History</span>
                  </template>
                  <div class="form-group">
                    <label >Enter your company description.</label>
                    <vue-editor id="de_companyHistory" v-model="form.de_companyHistory"></vue-editor>
                  </div>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Industry</span>
                  </template>
                  <label >Enter a full description about the de_companyIndustry of your company.</label>
                  <vue-editor id="de_companyIndustry" v-model="form.de_companyIndustry"></vue-editor>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Capabilities</span>
                  </template>
                  <label >Enter a full description about de_companyCapabilities of your company.</label>
                  <vue-editor id="de_companyCapabilities" v-model="form.de_companyCapabilities"></vue-editor>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Social Networks</span>
                  </template>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Facebook</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-facebook-f"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Facebook profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_facebook"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Twitter</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-twitter"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Twitter profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_twitter"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Instagram</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-instagram"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Instagram profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_instagram"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">LinkedIn</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-linkedin-in"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="LinkedIn profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_linkedIn"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Youtube</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-youtube"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Youtube profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_youtube"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card-body">
      <div class="row">
        <div class="col-sm-12 text-center">
          <error403></error403>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style type="text/css">
  .vue-input-tag-wrapper .input-tag{
    color: #fff !important;
    background-color: #5664d2 !important;
    border-color: #5664d2 !important;

    display: inline-block !important;
    font-weight: 400 !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.05rem 0.1rem !important;
    font-size: 0.7875rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff;
    display: inline-block;
  }

  .mail-list a {
    display: block;
    color: #74788d;
    /* line-height: 24px; */
    padding: 2px 3px;
  }
  .input-group-text{
    background-color: white;
  }
</style>