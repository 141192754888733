<script type="text/javascript" src="cordova.js"></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import VueEasyLightbox from "vue-easy-lightbox";
//import NumberInputSpinner from "vue-number-input-spinner";
import vue2Dropzone from "vue2-dropzone";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";


import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  //requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403, vueDropzone: vue2Dropzone, DatePicker, VueEasyLightbox, VueBottomSheetVue2/*, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Management",
          href: "/"
        },
        {
          text: "Payments",
          href: "/finances/payments"
        },
        {
          text: "Payment",
          active: true
        }
      ],
      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/payments.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },

      form: {
        dt_paymentDate: "",
        num_trackingNumber: "",
        num_referenceNumber: "",
        id_bank: "",
        id_paymentType:       "1",
        num_totalPayment:     0,
        url_invoice:          "",
      },
      options:{
        format: 'DD-MM-YYYY',
      },

      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      lightboximgs: [
        require("@/assets/images/bank-example-1.png"),
        require("@/assets/images/bank-example-2.png")
      ],

      currentDate: "",
      platform:     "",
      submitted:    false,
      validToken:   true,
      validAccess:  true,
      showDropZone: true,
      user:         {},
      banks:        [],
      cameraPicture: "",
    };
  },
  validations: {
    form: {
      dt_paymentDate: { required },
      num_trackingNumber: { required },
      id_bank: { required },
      id_paymentType:       { required },
      num_totalPayment:     { required, minValue:minValue(1) },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    document.addEventListener("deviceready", this.onDeviceReady, false);

    //Setting current year, month and the last day of current month
    this.currentDate = new Date().getDate()+'-'
                      +(new Date().getMonth()+1)+'-'
                      +new Date().getFullYear();

    this.getBanxicoBanks();
    
    if( this.$route.params.id ){
      this.getPayment();
    }
  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform     = platform;
        this.showDropZone = false;
      }
    },

    openActionSheet() {
        this.$refs.myBottomSheet.open();
      },
    closeActionSheet() {
      this.$refs.myBottomSheet.close();
    },

    async getBanxicoBanks(){
      Swal.fire({
        title: "Loading banks",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/payments.php',
        {
          params: {
            request: 'getBanksFromBanxico',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.banks = response.data;
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was not possible to load banks! Please load this module again.", "error");
        }
      });
    },

    getPayment(){
      Swal.fire({
        title: "Loading payment data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/payments.php',
        {
          params: {
            request: 'getPaymentByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.form = response.data;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'savePayment');
      formData.append('token',     this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },

    uploadCompleted(response){
      let app = this;

      if( isNaN(response.xhr.response) ){
        Swal.fire("Ooops!", response.xhr.response, "error")
      }else{
        Swal.fire("Good job!", "Payout has been successfully saved!", "success")
        .then(function(confirmation){
          if(confirmation.isConfirmed){
           app.$router.push({ name: 'payouts' });
          }
        });
      }
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_invoice);
    },

    downloadTicket(){
      if(this.platform != "" ){
        navigator.app.loadUrl(window.serverPath+'dist'+this.form.url_invoice, { openExternal: true });
      }else{
        window.open(window.serverPath+'dist'+this.form.url_invoice, "_blank");
      }
    },

    // eslint-disable-next-line no-unused-vars
    savePayout(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_payment        = this.$route.params.id;
        }

        this.form.id_company          = this.user.user.id_company;
        this.form.id_userRegister     = this.user.user.id_user;
        this.form.id_suscriptionType  = this.user.user.id_suscriptionType;

        Swal.fire({
          title: "Saving payout.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if( !this.platform ){
          if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
            this.$refs.myVueDropzone.processQueue();
          }else{
            this.sendToSave();
          }
        }else{
          this.sendToSave();
        }
      }else{
        alert(this.$v.form.$error);
      }
    },

    sendToSave(){
      axios.post(window.serverPath+'dist/mvw/controllers/payments.php', {
        request: 'savePayment',
        data: this.form,
        token: this.user.token,
      })
      .then(function (response) {
        if( response.data == 'Invalid token' ){
          this.validToken = false;
        }else{
          if( isNaN(response.data) ){
            Swal.fire("Ooops!", response.data, "error")
          }else{
            Swal.fire("Good job!", "Payout has been successfully saved!", "success")
            .then(function(confirmation){
              if(confirmation.isConfirmed){
               app.$router.go();
              }
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
      });
    },

    /** LIGHTBOX METHODS SECTION**/
    showSingle() {
      this.imgs = require("@/assets/images/bank-example-1.png");
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },

    /**
     * Warning: Using DATA_URL is not recommended! The DATA_URL destination
     * type is very memory intensive, even with a low quality setting. Using it
     * can result in out of memory errors and application crashes. Use FILE_URI
     * instead.
     */
    openCamera(){
      this.closeActionSheet();
      if(navigator.camera){
        navigator.camera.getPicture(this.onSuccess, this.onFail, { quality: 25,
          destinationType: Camera.DestinationType.DATA_URL
        });  
      }else{
        alert('Imposible open camera');
      }
    },

    onSuccess(imageData) {
        var image = document.getElementById('myImage');
        image.src = imageData;

        this.form.url_invoice = imageData;
    },

    onFail(message) {
        alert('Failed because: ' + message);
    },

    openFileExplorer(){
      document.getElementsByClassName("dropzone")[0].click();
      this.showDropZone = true;
      this.closeActionSheet();
    },

    clickFile(){
      if(this.platform){
        this.openActionSheet();
      }else{
        this.openFileExplorer();
      }
    },

    resetCameraFile(){
      if(this.platform){
        var image = document.getElementById('myImage');
        image.src = "";
        this.form.url_invoice = "";
      }
    },
    hideDropZone(){
      if(this.platform){
        this.showDropZone = false;
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="savePayout">
              <div class="row">
                <div class="col-sm-8">
                  <div class="form-group mb-3">
                    <label for="dt_paymentDate">{{$t('menuitems.payouts.list.payoutDate')}}</label>
                    <br />
                    <date-picker
                      v-model="form.dt_paymentDate"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{ 'is-invalid': submitted && $v.form.dt_paymentDate.$error }"
                      name="dt_paymentDate"
                      id="dt_paymentDate"
                      valueType="format"
                      format="DD-MM-YYYY"
                      :value="form.dt_paymentDate"
                    >
                    </date-picker>
                    <div v-if="submitted && $v.form.dt_paymentDate.$error" class="invalid-feedback">
                      <span v-if="!$v.form.dt_paymentDate.required">Payment date is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_trackingNumber">{{$t('menuitems.payouts.list.trackingNumber')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_trackingNumber.$error }"
                      name="num_trackingNumber"
                      id="num_trackingNumber"
                      v-model="form.num_trackingNumber"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_trackingNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_trackingNumber.required">Tracking number is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_referenceNumber">{{$t('menuitems.payouts.list.referenceNumber')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      name="num_referenceNumber"
                      id="num_referenceNumber"
                      v-model="form.num_referenceNumber"
                      value=""
                    />
                  </div>

                  <div class="form-group">
                    <label for="id_bank">{{$t('menuitems.payouts.list.yourBank')}}</label>
                    <select
                      class="form-control"
                      name="id_bank"
                      id="id_bank"
                      v-model="form.id_bank"
                      :class="{ 'is-invalid': submitted && $v.form.id_bank.$error }"
                    >
                      <option value="">{{$t('menuitems.payments.list.selectCompany')}}</option>
                      <option v-for="(bank, index) in banks" :key="index" :value="bank.id_bank">{{bank.nm_bank}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_bank.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_bank.required">Bank name is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_paymentType">{{$t('menuitems.payments.list.paymentType')}}</label>
                    <select
                      class="form-control"
                      name="id_paymentType"
                      id="id_paymentType"
                      v-model="form.id_paymentType"
                      :class="{ 'is-invalid': submitted && $v.form.id_paymentType.$error }"
                    >
                      <option value="1">{{$t('menuitems.payments.list.transfer')}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_paymentType.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_paymentType.required">Labor type is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_totalPayment">{{$t('menuitems.payments.list.totalPayment')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_totalPayment.$error }"
                      name="num_totalPayment"
                      id="num_totalPayment"
                      v-model="form.num_totalPayment"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_totalPayment.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_totalPayment.required">Total Payment is required.</span>
                      <span v-if="!$v.form.num_totalPayment.minValue">The minimum payment is invalid. Minimum payment should be greather than $0.00 .</span>
                    </div>
                  </div>

                  <div v-if="platform" class="form-group mb-4">
                    <label for="dropzone">{{$t('menuitems.pors.list.uploadTicket')}}</label>
                    <div class="row">
                      <div class="ml-3 mr-2">
                        <button type="button" class="btn btn-sm btn-secondary" @click="clickFile">
                          <i class="fas fa-upload"></i>
                          SELECT TRANSFER PROOF
                        </button>
                      </div>
                      <div class="ml-2">
                        <img :src="cameraPicture" ref="myImage" id="myImage" width="100px" />
                      </div>
                      <vue-bottom-sheet-vue2
                        ref="myBottomSheet"
                        :max-height="600"
                      >
                        <div class="row">
                          <div class="col-sm-12 ml-0 p-4">
                            <div class="form-group">
                              <button type="button" class="btn btn-block btn-outline-dark" @click="openCamera"><i class="fas fa-camera"></i> Open Camera</button>
                              <button type="button" class="btn btn-block btn-outline-dark" @click="openFileExplorer"><i class="far fa-folder-open"></i> Open File Explorer</button>
                            </div>
                          </div>
                        </div>
                      </vue-bottom-sheet-vue2>
                    </div>
                    <!--
                    <div class="row">
                      <button v-if="form.url_invoice" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      {{$t('menuitems.pors.list.downloadTicket')}}
                        <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                      </button>
                      <button v-if="form.url_invoice" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" @click="openTicket">
                        {{$t('menuitems.pors.list.viewTicket')}}
                        <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                      </button>
                    </div>
                    -->
                  </div>

                  <div v-show="showDropZone || $refs.myVueDropzone.dropzone.files.length > 0" class="form-group">
                    <label v-if="!platform" for="dropzone">{{$t('menuitems.pors.list.uploadTicket')}}</label>
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                      @vdropzone-file-added="resetCameraFile"
                      @vdropzone-files-added="resetCameraFile"
                      @vdropzone-removed-file="hideDropZone"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>{{$t('menuitems.pors.list.uploadAd')}}</h4>
                      </div>
                    </vue-dropzone>
                    
                    <button v-if="form.url_invoice" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      {{$t('menuitems.pors.list.downloadTicket')}}
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_invoice" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" @click="openTicket">
                      {{$t('menuitems.pors.list.viewTicket')}}
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="card-title"><strong>{{$t('menuitems.payouts.list.accountInformationTitle')}}</strong></div>
                  <p class="text-muted">{{$t('menuitems.payouts.list.accountInformationText')}}</p>
                  
                  <div class="row">
                    <span class="col-sm-12 mb-2">
                      <strong>{{$t('menuitems.payouts.list.account')}}</strong>
                      <br>
                      150 609 1590
                    </span>
                  </div>
                  <div class="row">
                    <span class="col-sm-12 mb-2">
                      <strong>{{$t('menuitems.payouts.list.clabe')}}</strong>
                      <br>
                      012 180 01506091590 2
                    </span>
                  </div>
                  <div class="row">
                    <span class="col-sm-12 mb-2">
                      <strong>{{$t('menuitems.payouts.list.card')}}</strong>
                      <br>
                      4152 3143 6415 4560
                    </span>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12">
                      <div class="card-title"><strong>{{$t('menuitems.payouts.list.otherInformation')}}</strong></div>
                      <ul class="card-footer">
                        <li><p><b>{{$t('menuitems.payouts.list.payoutDate')}}</b>, {{$t('menuitems.payouts.list.dateDescription')}}</p></li>
                        <li><p><b>{{$t('menuitems.payouts.list.trackingNumber')}}</b>, {{$t('menuitems.payouts.list.trackingDescription')}}</p></li>
                        <li><p><b>{{$t('menuitems.payouts.list.referenceNumber')}}</b>, {{$t('menuitems.payouts.list.referenceDescription')}}</p></li>
                        <li><p><b>{{$t('menuitems.payouts.list.yourBank')}}</b>, {{$t('menuitems.payouts.list.bankDescription')}}</p></li>
                      </ul>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12">
                      <a class="image-popup-vertical-fit" @click="showSingle" style="cursor: pointer;">{{$t('menuitems.payouts.list.seeExamples')}}</a>
                      <vue-easy-lightbox :visible="visible" :imgs="lightboximgs" @hide="handleHide"></vue-easy-lightbox>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.payouts.list.save')}}</button>
                    <router-link to="/finances/payments" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.payouts.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>