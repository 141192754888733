<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403'

import {
  required,
  //email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403 },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      componentKey: 0,
      items: [
        {
          text: "Management",
          href: "/"
        },
        {
          text: "Trades",
          href: "/management/trades"
        },
        {
          text: "Trade",
          active: true
        }
      ],
      form: {
        nm_trade: "",
      },
      submitted: false,
      validToken: true,
      validAccess: true,
      user: {},
      trades: []
    };
  },
  validations: {
    form: {
      nm_trade: {required, minLength: minLength(2), maxLength: maxLength(50)},
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      this.id_trade = this.$route.params.id;
      this.getIndustryByID( this.$route.params.id );
    }
    appConfig.title = function(){
      return "dsdas";
    };
  },
  methods: {
    getIndustryByID(idIndustry){
      let app = this;

      Swal.fire({
        title: "Loading trade data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });


      axios
      .get(
        window.serverPath+'dist/mvw/controllers/trades.php',
        {
          params: {
            request: 'getTradeByID',
            id: idIndustry,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        Swal.close();
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            if(response.data){
              app.form.nm_trade = response.data.nm_trade;
            }else{
              Swal.fire('Ooops!!!','<p><b>Seems like an error had just occured.<br>Please try again.</b></p>','error');
            }
          }
        }else{
          Swal.fire('Ooops!!!','<p><b>Seems like an error had just occured.<br>Please try again.</b></p>','error');
        }
      })
      .catch(function (error) {
        app.isLoading = false;
        if(
          error.code == "ERR_BAD_RESPONSE"    ||
          error.code == "ERR_NETWORK"
        ){
          Swal.fire('Ooops!!!','<p><b>Something wrong with the communication between your computer and our server.<br>Please check your internet connection.</b></p>','error');
        }
      });
    },


    // eslint-disable-next-line no-unused-vars
    saveIndustry(e) {
      let app = this;
      let successMessage = "";

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_trade = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving trade.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/trades.php', {
          request: 'saveTrade',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          let action = "";

          if(response.status == 500){ app.$router.push({ name: 'Error-500' }); }
          if(response.status == 404){ app.$router.push({ name: 'Error-404' }); }
          if(response.status == 401){ app.$router.push({ name: 'Error-401' }); }

          if( response.status == 200 ){
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              if( response.data === "OK" ){
                action = "saved";
              }
              if( response.data === "UPDATED" ){
                action = "updated";
              }

              Swal.fire("Good job!", "The trade is correct "+action+"!", "success");
              app.$router.push({ name: 'trades' });

              if(response.data){
                Swal.fire(
                  'Congrats!!!','<p><b>'+successMessage+'</b></p>',
                  'success',
                ).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    app.$router.push({ name: 'trades'});
                  }
                });
              }else{
                Swal.fire('Ooops!!!','<p><b>Seems like an error had just occured.<br>Please try again.</b></p>','error');
              }
            }
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });

        /*
        Swal.fire("Good job!", "Your trade is saved now!", "success")
        .then(() => {
          self.submitted = false;
          self.form.id_trade       = "";
          self.form.nm_trade = "";
        });
        */
      }
    },

    resetSite(){
      this.form.nm_trade = "";
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveIndustry">
              <div class="row">
                <div class="col-lg-4" >
                  <h4 class="card-title">{{$t('menuitems.trades.list.trade')}}</h4>
                  <p class="card-title-desc">{{$t('menuitems.trades.list.register')}}</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_trade">{{$t('menuitems.trades.list.tradeName')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_trade.$error }"
                          name="nm_trade"
                          id="nm_trade"
                          v-model="form.nm_trade"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_trade.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_trade.required">Trade name is required.</span>
                          <span v-if="!$v.form.nm_trade.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_trade.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                </div>

                <div class="col-lg-4">
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.trades.list.save')}}</button>
                    <router-link to="/company/trades" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.trades.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>