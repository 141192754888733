<script>
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';

import {
  required,
  //email,
  minLength,
  //sameAs,
  maxLength,
  minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  //requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {  /*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Shop",
          href: "/"
        },
        {
          text: "Labors",
          href: "/Shop/labors"
        },
        {
          text: "Labor",
          active: true
        }
      ],
      form: {
        id_site:            "",
        id_laborType:       "",
        de_labor:           "",
        num_laborPrice:     0,
      },
      submitted:    false,
      validToken:   true,
      validAccess:  true,
      user:         {},
      sites:        [],
    };
  },
  validations: {
    form: {
      id_site:            { required },
      id_laborType:       { required },
      de_labor:           { required, minLength: minLength(2), maxLength: maxLength(200) },
      num_laborPrice:     { required, minValue:minValue(1) },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.getSites();
    
    if( this.$route.params.id ){
      this.getLabor();
    }
  },
  methods: {
    emit(form){
      Swal.close();
      this.$emit('success');
      this.$emit('addLabor', form);
    },

    async getSites(){
      Swal.fire({
        title: "Loading Sites",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSites',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.sites = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getLabor(){
      Swal.fire({
        title: "Loading labor data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/labors.php',
        {
          params: {
            request: 'getLaborsByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            if( Array.isArray(response.data) ){
              app.labor = response.data;

              this.form.id_site                   = app.labor.id_site;
              this.form.de_labor                  = app.labor.de_labor;
              this.form.num_laborPrice            = app.labor.num_laborPrice;
              this.form.id_laborType              = app.labor.id_laborType;
            }

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    saveCustomer(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          if( this.$route.name != 'workorderEdit'){
            this.form.id_labor = this.$route.params.id;
          }
        }

        Swal.fire({
          title: "Saving labor.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/labors.php', {
          request: 'saveLabor',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let labor;
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            if( !isNaN(response.data) ){
              labor = {
                id_labor        : response.data,
                id_laborType    : parseInt(app.form.id_laborType),
                labor           : app.form.de_labor,
                quantity        : 0,
                shop_price      : "0.00",
                suggested_price : parseFloat(app.form.num_laborPrice).toFixed(2)
              }
            }

            Swal.fire(
              "Good job!",
              "Your labor is correct "+action+"!",
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                //app.$router.push({ name: 'industries'});
                app.emit(labor);
              }
            });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
  }
};
</script>

<template>

            <form class="needs-validation" @submit.prevent="saveCustomer">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="de_labor">{{$t('menuitems.labors.list.laborName')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_labor.$error }"
                      name="de_labor"
                      id="de_labor"
                      v-model="form.de_labor"
                      value=""
                    >
                    <div v-if="submitted && $v.form.de_labor.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_labor.required">Labor name is required.</span>
                      <span v-if="!$v.form.de_labor.minLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                      <span v-if="!$v.form.de_labor.maxLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_laborType">{{$t('menuitems.labors.list.laborType')}}</label>
                    <select
                      class="form-control"
                      name="id_laborType"
                      id="id_laborType"
                      v-model="form.id_laborType"
                      :class="{ 'is-invalid': submitted && $v.form.id_laborType.$error }"
                    >
                      <option value="">{{$t('menuitems.labors.list.selectLaborType')}}</option>
                      <option value="1">{{$t('menuitems.labors.list.sparePart')}}</option>
                      <option value="2">{{$t('menuitems.labors.list.workforce')}}</option>
                      <option value="3">{{$t('menuitems.labors.list.externalService')}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_laborType.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_laborType.required">Labor type is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_laborPrice">{{$t('menuitems.labors.list.laborPrice')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_laborPrice.$error }"
                      name="num_laborPrice"
                      id="num_laborPrice"
                      v-model="form.num_laborPrice"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_laborPrice.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_laborPrice.required">Labor price is required.</span>
                      <span v-if="!$v.form.num_laborPrice.minValue">This minimum price is invalid. Price should be greather than $0.00 .</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_site">{{$t('menuitems.labors.list.site')}}</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_site.$error }"
                      name="id_site"
                      id="id_site"
                      v-model="form.id_site"
                    >
                      <option value="">{{$t('menuitems.labors.list.selectSite')}}</option>
                      <option v-for="(site, index) in sites" :key="index" :value="site.id_site">{{site.nm_site}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_site.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_site.required">Site field is required.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">{{$t('menuitems.labors.list.save')}}</button>
                    <router-link to="/shop/labors" class="ml-3">
                      <button class="btn btn-danger" type="reset">{{$t('menuitems.labors.list.cancel')}}</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>

</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>